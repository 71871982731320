import React, { Component } from "react";
import Event from "./Event.js";
import _ from "underscore";

export default class MatchLog extends Component {
  componentDidUpdate() {
    // if (this.eventLogEnd) {
    //   this.eventLogEnd.scrollIntoView({});
    // }
  }

  render() {
    const { events, startTime } = this.props;

    const eventComponents = _.map(events, (event, i) => {
      return <Event key={i} event={event} startTime={startTime} />;
    });

    return (
      <div className="log">
        {eventComponents}
        <div ref={ref => (this.eventLogEnd = ref)} />
      </div>
    );
  }
}
