import React, { Component } from "react";
import _ from "underscore";

import HardwareStatus from "../components/HardwareStatus";

class Hardware extends Component {
  renderHardwareComponents(tags, cabinetStatuses, piStatuses) {
    return _.map(tags, tag => {
      return (
        <React.Fragment>
          <HardwareStatus
            tag={tag}
            type={"cabinet"}
            {...cabinetStatuses[tag]}
          />
          <HardwareStatus tag={tag} type={"pi"} {...piStatuses[tag]} />
        </React.Fragment>
      );
    });
  }

  render() {
    const { tags, cabinetStatuses, piStatuses } = this.props;

    return (
      <div className="page">
        {this.renderHardwareComponents(tags, cabinetStatuses, piStatuses)}
      </div>
    );
  }
}

export default Hardware;
