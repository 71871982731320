import React, { Component } from "react";

import Player from "./Player";

import _ from "underscore";

class Team extends Component {
  componentDidMount() {
    const {
      socket,
      requestCurrentMatchByTag,
      match: {
        params: { tag }
      }
    } = this.props;

    if (socket) {
      socket.onmessage = event => {
        requestCurrentMatchByTag(tag);
      };
    }
  }
  renderPlayerComponents(matchData, playerIds, team) {
    // TODO: Move this into a helper function
    const players = _.chain(matchData.players)
      .filter(player => {
        return playerIds.includes(player.id) ? player : null;
      })
      .sortBy((a, b) => {
        return playerIds.indexOf(a.id) - playerIds.indexOf(b.id);
      })
      .value();

    return _.map(players, player => {
      return (
        <Player
          key={player.id}
          team={team}
          player={player}
          events={matchData.events}
        />
      );
    });
  }

  render() {
    const {
      currentMatch,
      playerIds,
      team,
      match: {
        params: { tag }
      }
    } = this.props;
    const matchData = currentMatch.data || {};

    return (
      <div className="stream-team">
        {this.renderPlayerComponents(matchData, playerIds, team)}
      </div>
    );
  }
}

export default Team;
