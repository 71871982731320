import React, { Component } from "react";
import moment from "moment";

import messageHelper from "../../helpers/messageHelper";

class MatchTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: 0,
      start: 0
    };

    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
  }
  componentDidMount() {
    const {
      socket,
      requestCurrentMatchByTag,
      currentMatch,
      match: {
        params: { tag }
      }
    } = this.props;

    if (socket) {
      socket.onmessage = event => {
        const messageType = messageHelper.parseMessage(event.data).type;
        if (["gamestart", "gameend", "victory"].includes(messageType)) {
          requestCurrentMatchByTag(tag);
          this.checkTimer();
        }
      };
    }

    this.checkTimer();
  }

  checkTimer() {
    const { currentMatch } = this.props;
    const matchData = currentMatch.data || {};

    if (matchData.endTime === null) {
      this.startTimer(matchData);
    } else {
      this.stopTimer(matchData);
    }
  }

  startTimer(matchData) {
    this.timer = setInterval(() => {
      this.setState({
        time: moment.now() - moment(matchData.startTime)
      });
    }, 1);
  }

  stopTimer(matchData) {
    this.setState({
      time: moment(matchData.endTime) - moment(matchData.startTime)
    });
    clearInterval(this.timer);
  }

  render() {
    const {
      currentMatch,
      match: {
        params: { tag }
      }
    } = this.props;

    return (
      <div className="match-timer">
        {moment(this.state.time).format("m:ss")}
      </div>
    );
  }
}

export default MatchTimer;
