const playerIds = (team, goldOnLeft) => {
  return (() => {
    switch (team) {
      case "both":
        return goldOnLeft
          ? [3, 5, 1, 7, 9, 4, 6, 2, 8, 10]
          : [4, 6, 2, 8, 10, 3, 5, 1, 7, 9];
        break;
      case "blue":
        return goldOnLeft ? [9, 7, 1, 5, 3] : [10, 8, 2, 6, 4];
      case "gold":
        return goldOnLeft ? [10, 8, 2, 6, 4] : [9, 7, 1, 5, 3];
      default:
        return [];
    }
  })();
};

module.exports = {
  playerIds
};
