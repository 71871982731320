import React, { Component } from "react";
import _ from "underscore";

import statCalculators from "../helpers/statCalculators";

export default class Player extends Component {
  calculateKills(id, events, type) {
    return _.reduce(
      events,
      (kills, event) => {
        if (event.type === "playerKill" && event.victor.id === id) {
          if (event.victim.type === type) {
            return kills + 1;
          } else if (type === "all") {
            return kills + 1;
          }
          return kills;
        } else {
          return kills;
        }
      },
      0
    );
  }
  calculateDeaths(id, events, type) {
    return _.reduce(
      events,
      (deaths, event) => {
        if (event.type === "playerKill" && event.victim.id === id) {
          if (event.victim.type === type) {
            return deaths + 1;
          } else if (type === "all") {
            return deaths + 1;
          }
          return deaths;
        } else {
          return deaths;
        }
      },
      0
    );
  }
  calculateBerries(id, events) {
    return _.reduce(
      events,
      (berries, event) => {
        if (event.type === "berryDeposit" && event.player.id === id) {
          return berries + 1;
        } else {
          return berries;
        }
      },
      0
    );
  }

  // TODO: Refactor this mess
  // Add better params for checking victim vs victor player types
  // e.g. drone vs queen bump, warrior vs queen bump, etc
  // Could be you, opponent and then pass in params like "any", "queen" or "drone", "queen" etc
  calculateBumps(id, events, type) {
    return _.reduce(
      events,
      (bumps, event) => {
        // REFACTOR: Issue with victor and victim sometimes not
        // showing up for glances in newer KQ version
        if (
          event.type === "glance" &&
          event.victor &&
          event.victim &&
          (event.victor.id === id || event.victim.id === id)
        ) {
          if (
            type === "queen" &&
            ((event.victor.id === id && event.victim.type === "queen") ||
              (event.victim.id === id && event.victor.type === "queen"))
          ) {
            return bumps + 1;
          } else if (
            type === "warrior" &&
            ((event.victor.id === id && event.victim.type === "warrior") ||
              (event.victim.id === id && event.victor.type === "warrior"))
          ) {
            return bumps + 1;
          } else if (
            type === "drone" &&
            ((event.victor.id === id && event.victim.type === "drone") ||
              (event.victim.id === id && event.victor.type === "drone"))
          ) {
            return bumps + 1;
          } else if (
            type === "bot" &&
            ((event.victor.id === id && event.victim.type === "bot") ||
              (event.victim.id === id && event.victor.type === "bot"))
          ) {
            return bumps + 1;
          } else if (type === "all") {
            return bumps + 1;
          }
          return bumps;
        } else {
          return bumps;
        }
      },
      0
    );
  }

  render() {
    const {
      id,
      name,
      team,
      slot,
      type,
      hasSpeed,
      hasBerry,
      isRidingSnail,
      isEating,
      isBeingEaten,
      isReserving
    } = this.props.player;
    const events = this.props.events;
    const imgUrl =
      "/players/" +
      team +
      "-" +
      type +
      (type === "bot" || type === "queen" ? "" : "-" + slot) +
      ".png";

    const matchTime =
      _.max(_.pluck(events, "time")) -
      _.find(events, { type: "gamestart" }).time;

    const assists = statCalculators.calculateAssists(events);
    const snailStats = statCalculators.calculateSnailStats(events);
    const statusUptime = statCalculators.calculateStatusUptime(events);

    const {
      warriorGatesUsed,
      warriorGateAttempts,
      speedGatesUsed,
      speedGateAttempts,
      berriesPickedUp,
      berriesDeposited,
      berriesKickedIn
    } = statusUptime[id];

    const queenKills = this.calculateKills(id, events, "queen");
    const warriorKills = this.calculateKills(id, events, "warrior");
    const droneKills = this.calculateKills(id, events, "drone");
    const botKills = this.calculateKills(id, events, "bot");
    const kills = queenKills + warriorKills + droneKills + botKills;

    const queenBumps = this.calculateBumps(id, events, "queen");
    const warriorBumps = this.calculateBumps(id, events, "warrior");
    const droneBumps = this.calculateBumps(id, events, "drone");
    const botBumps = this.calculateBumps(id, events, "bot");
    const bumps = queenBumps + warriorBumps + droneBumps + botBumps;

    const queenDeaths = this.calculateDeaths(id, events, "queen");
    const warriorDeaths = this.calculateDeaths(id, events, "warrior");
    const droneDeaths = this.calculateDeaths(id, events, "drone");
    const botDeaths = this.calculateDeaths(id, events, "bot");
    const deaths = queenDeaths + warriorDeaths + droneDeaths + botDeaths;

    const berries = this.calculateBerries(id, events);

    return (
      <div className={"player " + team}>
        <div className="name">
          <img alt={imgUrl} src={imgUrl} />
          <div>{name}</div>
        </div>
        <div className="header">
          <div />
          <div>Total</div>
          <div>Queen</div>
          <div>Warrior</div>
          <div>Drone</div>
          <div>Bot</div>
        </div>
        <div className="kills">
          <div>Kills</div>
          <div>{kills}</div>
          <div>{queenKills}</div>
          <div>{warriorKills}</div>
          <div>{droneKills}</div>
          <div>{botKills}</div>
        </div>
        <div className="assists">
          <div>Assists</div>
          <div>{assists[id]["total"]}</div>
          <div>{assists[id]["queen"]}</div>
          <div>{assists[id]["warrior"]}</div>
          <div>{assists[id]["drone"]}</div>
          <div>{assists[id]["bot"]}</div>
        </div>
        <div className="bumps">
          <div>Bumps</div>
          <div>{bumps}</div>
          <div>{queenBumps}</div>
          <div>{warriorBumps}</div>
          <div>{droneBumps}</div>
          <div>{botBumps}</div>
        </div>
        <div className="deaths">
          <div>Deaths</div>
          <div>{deaths}</div>
          <div>{queenDeaths}</div>
          <div>{warriorDeaths}</div>
          <div>{droneDeaths}</div>
          <div>{botDeaths}</div>
        </div>
        {![1, 2].includes(id) && (
          <React.Fragment>
            <div className="stats-block uptime-stats">
              <div className="header">Uptime</div>
              <div className="subheader">(S) Warrior</div>
              <div className="subheader">Warrior</div>
              <div className="subheader">Speed</div>
              <div className="value">
                {(statusUptime[id].speedWarriorUptime / 1000).toFixed(1)}s
              </div>
              <div className="value">
                {(
                  (statusUptime[id].speedWarriorUptime / matchTime) *
                  100
                ).toFixed(1)}
                %
              </div>
              <div className="value">
                {(statusUptime[id].warriorUptime / 1000).toFixed(1)}s
              </div>
              <div className="value">
                {((statusUptime[id].warriorUptime / matchTime) * 100).toFixed(
                  1
                )}
                %
              </div>
              <div className="value">
                {(statusUptime[id].speedUptime / 1000).toFixed(1)}s
              </div>
              <div className="value">
                {((statusUptime[id].speedUptime / matchTime) * 100).toFixed(1)}%
              </div>
            </div>
            <div className="stats-block gate-stats">
              <div className="header">Gate Stats</div>
              <div className="subheader">W Used</div>
              <div className="subheader">W Tries</div>
              <div className="subheader">S Used</div>
              <div className="subheader">S Tries</div>
              <div className="value">{warriorGatesUsed}</div>
              <div className="value">{warriorGateAttempts}</div>
              <div className="value">{speedGatesUsed}</div>
              <div className="value">{speedGateAttempts}</div>
            </div>
            <div className="stats-block berry-stats">
              <div className="header">Berry Stats</div>
              <div className="subheader">Pick ups</div>
              <div className="subheader">Deposits</div>
              <div className="subheader">Kick ins</div>
              <div className="subheader">BPM</div>
              <div className="value">{berriesPickedUp}</div>
              <div className="value">{berriesDeposited}</div>
              <div className="value">{berriesKickedIn}</div>
              <div className="value">
                {(
                  (berriesDeposited + berriesKickedIn) /
                  (matchTime / 1000 / 60)
                ).toFixed(2)}
              </div>
            </div>
            <div className="stats-block snail-stats">
              <div className="header">Snail Stats</div>
              <div className="subheader">Distance</div>
              <div className="subheader">Uptime</div>
              <div className="value">{snailStats[id].distance}</div>
              <div className="value">
                {((snailStats[id].distance / 1920) * 100).toFixed(2)}%
              </div>
              <div className="value">
                {(statusUptime[id].snailUptime / 1000).toFixed(1)}s
              </div>
              <div className="value">
                {((statusUptime[id].snailUptime / matchTime) * 100).toFixed(1)}%
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="status">
          <div>{hasSpeed ? "Speed" : "-"}</div>
          <div>{hasBerry ? "Berry" : "-"}</div>
          <div>{isRidingSnail ? "Snail" : "-"}</div>
          <div>{isEating ? "Eating" : "-"}</div>
          <div>{isBeingEaten ? "Eaten" : "-"}</div>
          <div>{isReserving ? "Res" : "-"}</div>
        </div>
      </div>
    );
  }
}
