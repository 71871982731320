import React, { Component } from "react";
import Player from "./Player.js";

import _ from "underscore";
import moment from "moment";

export default class MatchStats extends Component {
  maidenEventFilter(events, side, type) {
    return _.filter(events, event => {
      if (event.maiden) {
        return (
          event.type === "blessMaiden" &&
          event.maiden.side === side &&
          event.maiden.type === type
        );
      } else {
        return null;
      }
    });
  }

  maidenUptime(events, side, type) {
    const maidenEvents = this.maidenEventFilter(events, side, type);
    const startEvent = _.find(events, { type: "gamestart" });
    const endEvent =
      _.find(events, { type: "gameend" }) ||
      _.find(events, { type: "victory" });
    let previousEvent = startEvent;

    return _.reduce(
      [startEvent, ...maidenEvents, endEvent],
      (uptime, event) => {
        if (!event) return uptime;

        if (!previousEvent) {
          previousEvent = event;
          return uptime;
        }

        let newUptime = uptime;
        let timeDifference = event.time - previousEvent.time;

        if (previousEvent.type === "gamestart") {
          newUptime = {
            ...uptime,
            neutral: timeDifference
          };
        } else {
          if (event.team !== previousEvent.team) {
            let key = "";
            if (previousEvent.team) {
              key = previousEvent.team.toLowerCase();
            } else {
              key = "neutral";
            }
            newUptime = {
              ...uptime,
              [key]: uptime[key] + timeDifference
            };
          }
        }

        previousEvent = event;
        return newUptime;
      },
      { neutral: 0, gold: 0, blue: 0 }
    );
  }

  render() {
    const { match, goldOnLeft, playerOrder } = this.props;

    let {
      winningTeam,
      winCondition,
      endTime,
      startTime,
      map,
      players,
      maidens,
      berries,
      events
    } = match.data;

    const startDate = startTime
      ? moment.unix(startTime / 1000).format("llll")
      : "Pregame";

    const gameTime = endTime
      ? `${(moment(endTime) - moment(startTime)) / 1000} seconds`
      : "In Progress";

    const playerComponents = (() => {
      return _.map(playerOrder, id => {
        const player = _.find(players, { id });
        return <Player key={player.id} player={player} events={events} />;
      });
    })();

    // TODO: These will probably be left/right checking and just
    // refactored in the future based on final berry object format.
    // ***** Berries *****
    const goldBerries = _.reduce(
      berries,
      (count, berry) => {
        if (berry.playerId % 2 === 1) return count + 1;
        return count;
      },
      0
    );
    const blueBerries = _.reduce(
      berries,
      (count, berry) => {
        if (berry.playerId % 2 === 0) return count + 1;
        return count;
      },
      0
    );
    const berryComponents = goldOnLeft ? (
      <React.Fragment>
        <div className="header">Berries</div>
        <div className="gold">{goldBerries}</div>
        <div className="blue">{blueBerries}</div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="header">Berries</div>
        <div className="blue">{blueBerries}</div>
        <div className="gold">{goldBerries}</div>
      </React.Fragment>
    );

    // TODO: Refactor this goldOnLeft handling
    // TODO: Can also be refactored to just arbitrarily sort by type/side
    // ***** Maidens *****
    const maidenOrder = (map => {
      switch (map) {
        case "map_bonus_military":
          return [
            { type: "warrior", side: "left" },
            { type: "warrior", side: "center" },
            { type: "warrior", side: "right" },
            { type: "speed", side: "center" }
          ];
        case "map_bonus_snail":
          return [
            { type: "warrior", side: "left" },
            { type: "warrior", side: "center" },
            { type: "warrior", side: "right" },
            { type: "speed", side: "left" },
            { type: "speed", side: "center" },
            { type: "speed", side: "right" }
          ];
        case "map_nik":
          return [
            { type: "unknown", side: "left" },
            { type: "unknown", side: "left" },
            { type: "unknown", side: "center" },
            { type: "unknown", side: "right" },
            { type: "unknown", side: "right" }
          ];
        case "map_josh":
          return [
            { type: "unknown", side: "left" },
            { type: "unknown", side: "left" },
            { type: "unknown", side: "center" },
            { type: "unknown", side: "right" },
            { type: "unknown", side: "right" }
          ];
        default:
          return [
            { side: "left", type: "speed" },
            { side: "left", type: "warrior" },
            { side: "center", type: "warrior" },
            { side: "right", type: "warrior" },
            { side: "right", type: "speed" }
          ];
      }
    })(map);

    const maidenComponents = _.map(maidenOrder, (maiden, i) => {
      const { side, type } = maiden;
      const maidenUptime = this.maidenUptime(events, side, type);
      let maidenObject = _.find(maidens, { side, type });
      let maidenTeam = maidenObject ? maidenObject.team : "neutral";

      const { gold, neutral, blue } = maidenUptime;
      let totalTime = gold + neutral + blue;
      totalTime = totalTime === 0 ? 1 : totalTime;

      const maidenData = goldOnLeft ? (
        <React.Fragment>
          <div className="gold">Gold</div>
          <div>Neutral</div>
          <div className="blue">Blue</div>
          <div className="gold">{(gold / 1000).toFixed(2)}</div>
          <div>{(neutral / 1000).toFixed(2)}</div>
          <div className="blue">{(blue / 1000).toFixed(2)}</div>
          <div className="gold">{((gold / totalTime) * 100).toFixed(2)}%</div>
          <div>{((neutral / totalTime) * 100).toFixed(2)}%</div>
          <div className="blue">{((blue / totalTime) * 100).toFixed(2)}%</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="blue">Blue</div>
          <div>Neutral</div>
          <div className="gold">Gold</div>
          <div className="blue">{(blue / 1000).toFixed(2)}</div>
          <div>{(neutral / 1000).toFixed(2)}</div>
          <div className="gold">{(gold / 1000).toFixed(2)}</div>
          <div className="blue">{((blue / totalTime) * 100).toFixed(2)}%</div>
          <div>{((neutral / totalTime) * 100).toFixed(2)}%</div>
          <div className="gold">{((gold / totalTime) * 100).toFixed(2)}%</div>
        </React.Fragment>
      );

      return (
        <div className="maiden-uptime" key={i}>
          <div
            className={
              "header " + (maidenTeam ? maidenTeam.toLowerCase() : "neutral")
            }
          >
            {maiden.side} {maiden.type} {maidenTeam}
          </div>
          {maidenData}
        </div>
      );
    });

    return (
      <div className="stats">
        <div
          className={"info " + (winningTeam ? winningTeam.toLowerCase() : "")}
        >
          {match.id && <div>Match ID: {match.id}</div>}
          <div>{startDate}</div>
          <div>
            {map.replace("map_", "").replace("_", " ")} - {winningTeam} -{" "}
            {winCondition}
          </div>
          <div>Duration: {gameTime}</div>
        </div>
        <div className="players">{playerComponents}</div>
        <div className="berries">{berryComponents}</div>
        <div className="maidens">{maidenComponents}</div>
      </div>
    );
  }
}
