import React, { Component } from "react";
import statCalculators from "../../helpers/statCalculators"

class BerriesUsed extends Component {
  componentDidMount() {
    const {
      socket,
      requestCurrentMatchByTag,
      match: {
        params: { tag }
      }
    } = this.props;

    if (socket) {
      socket.onmessage = event => {
        requestCurrentMatchByTag(tag);
      };
    }
  }

  berriesUsed(events, team) {
    let berryCounts = statCalculators.berriesUsed(events)
    return berryCounts[team]
  }

  render() {
    const {
      currentMatch,
      match: {
        params: { tag, team }
      }
    } = this.props;
    const matchData = currentMatch.data || {};

    return (
      <div className={`berries-remaining berries-remaining--${team}`}>
        <div className="berries-remaining__count">{this.berriesUsed(matchData.events, team)}</div>
      </div>
    );
  }
}

export default BerriesUsed;
