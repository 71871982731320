import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Header from "../containers/Header";
import Content from "../containers/Content";
import Sidebar from "../containers/Sidebar";
import Stream from "../containers/Stream";

import messageHelper from "../helpers/messageHelper";

import "../style/App.scss";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { match, fetchCurrentMatch } = this.props;

    const socket = new WebSocket(process.env.REACT_APP_WS_URL);

    const tag = match.params.tag || "bmore"

    this.setState({ socket });

    fetchCurrentMatch(tag);
    this.fetchHardwareStatus();
    this.fetchTags();
  }

  fetchTags() {
    fetch("/api/tags")
      .then(r => r.json())
      .then(tags => {
        this.setState({ tags });
      });
  }
  fetchHardwareStatus() {
    this.fetchPiStatus();
    this.fetchCabinetStatus();
  }
  fetchPiStatus() {
    fetch("/api/pi_status")
      .then(r => r.json())
      .then(piStatuses => {
        this.setState({ piStatuses });
      });
  }
  fetchCabinetStatus() {
    fetch("/api/cabinet_status")
      .then(r => r.json())
      .then(cabinetStatuses => {
        this.setState({ cabinetStatuses });
      });
  }

  render() {
    const { tags, piStatuses, cabinetStatuses, socket } = this.state;

    return (
      <Switch>
        <Route
          path="/stream/:tag"
          component={props => <Stream {...props} socket={socket} />}
        />
        <div className="App">
          <Header />
          <Route path="/:tag/matches" component={Sidebar} />
          <Route path="/matches" component={Sidebar} />
          <Content
            socket={socket}
            tags={tags}
            piStatuses={piStatuses}
            cabinetStatuses={cabinetStatuses}
          />
        </div>
      </Switch>
    );
  }
}

export default App;
