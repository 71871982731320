import React, { Component } from "react";
import statCalculators from "../../helpers/statCalculators"

class BerriesRemaining extends Component {
  componentDidMount() {
    const {
      socket,
      requestCurrentMatchByTag,
      match: {
        params: { tag }
      }
    } = this.props;

    if (socket) {
      socket.onmessage = event => {
        requestCurrentMatchByTag(tag);
      };
    }
  }

  berriesRemaining(events, map) {
    const mapBerries = {
      map_day: 66,
      map_night: 54,
      map_dusk: 66,
      map_twilight: 60
    }

    let berriesOnMap = mapBerries[map]

    if (berriesOnMap) {
      let berryCounts = statCalculators.berriesUsed(events)
      let berriesUsed = berryCounts.gold + berryCounts.blue + berryCounts.unknown

      let berriesRemaining = berriesOnMap - (berriesUsed % berriesOnMap)

      if (berriesRemaining === berriesOnMap && berriesUsed > 0) {
        berriesRemaining = 0
      }

      return berriesRemaining
    } else {
      return "?"
    }
  }

  render() {
    const {
      currentMatch,
      match: {
        params: { tag }
      }
    } = this.props;
    const matchData = currentMatch.data || {};

    return (
      <div className="berries-remaining">
        <div className="berries-remaining__count">{this.berriesRemaining(matchData.events, matchData.map)}</div>
      </div>
    );
  }
}

export default BerriesRemaining;
