import React from "react";
import moment from "moment";

const HardwareStatus = props => {
  const { tag, type, status, time } = props;

  return (
    <div className={"c-hardware-status c-hardware-status--" + status}>
      <div className="c-hardware-status__title">
        {tag} {type} status:{" "}
      </div>
      <div className="c-hardware-status__status">{status}</div>
      <div className="c-hardware-status__time">
        {moment.unix(time / 1000).format("llll")}
      </div>
    </div>
  );
};

export default HardwareStatus;
