import React, { Component } from "react";
import _ from "underscore";
import moment from "moment";

export default class GameSelector extends Component {
  render() {
    const {
      previousGames,
      selectedGameId,
      onSelectGame,
      matchInProgress
    } = this.props;

    const matchInProgressComponent = (
      <div
        className="c-game c-game--in-progress"
        onClick={this.props.onViewCurrentMatch}
      >
        {matchInProgress ? "Match in Progress" : "No Match in Progress"}
      </div>
    );

    const previousGamesComponents = _.map(previousGames, game => {
      return (
        <Game
          key={game.id}
          game={game}
          onSelectGame={onSelectGame}
          isSelected={selectedGameId === game.id}
        />
      );
    });

    return (
      <div className="l-game-list">
        {matchInProgressComponent}
        {previousGamesComponents}
      </div>
    );
  }
}

class Game extends Component {
  render() {
    const { game, current, onSelectGame, isSelected } = this.props;
    const { startTime, winningTeam, winCondition, map } = game;

    const inProgress = current === true && game.winCondition === null;
    const completed = game.winCondition !== null;

    // TODO: If game complete and current then return
    // (current bug pending architecture changes)
    if (completed && current) return null;

    const gameTime = game.endTime ? (
      <span>
        {(moment(game.endTime) - moment(game.startTime)) / 1000} seconds
      </span>
    ) : null;

    // TODO: This on click should probably instead be handled with the react router
    return (
      <div
        className={
          "c-game c-game" +
          (inProgress ? "--in-progress" : "") +
          (completed ? "--completed" : "") +
          " " +
          (isSelected ? "is-selected" : "")
        }
        onClick={onSelectGame.bind(null, game.id)}
      >
        <div>{inProgress ? "In Progress" : ""}</div>
        <div>Match ID: {game.id}</div>
        <div>{moment(startTime).format("llll")}</div>
        <div>
          {map.replace("map_", "").replace("_", " ")} - {winningTeam} -{" "}
          {winCondition}
        </div>
        <div>Duration: {gameTime}</div>
      </div>
    );
  }
}
