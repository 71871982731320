import React from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import { matchActions } from "../actions";

import Directory from "../components/Directory";
import GameDetails from "../components/GameDetails";
import OverallStats from "../components/OverallStats";
import Players from "../components/Players";
import Hardware from "../components/Hardware";

class Content extends React.Component {

  render() {
    const { socket, requestCurrentMatch, fetchMatchInfo, selectedMatch, currentMatch, viewingCurrentMatch, matchInProgress } = this.props;

    let match = null;
    if (currentMatch.data) {
      match = currentMatch;
    }

    return (
      <div className="content">
        <Route exact path="/" component={Directory} />
        <Route path="/stats" component={OverallStats} />
        <Route
          path="/hardware"
          render={props => <Hardware {...props} {...this.props} />}
        />
        <Route path="/players" component={Players} />
        <Route
          path="/:tag/matches"
          render={props => (
            <GameDetails
              {...props}
              socket={socket}
              requestCurrentMatch={requestCurrentMatch}
              viewingCurrentMatch={viewingCurrentMatch}
              matchInProgress={matchInProgress}
              fetchMatchInfo={fetchMatchInfo}
              selectedMatch={viewingCurrentMatch ? match : selectedMatch}
            />
          )}
        />
        <Route
          path="/matches"
          render={props => (
            <GameDetails
              {...props}
              socket={socket}
              requestCurrentMatch={requestCurrentMatch}
              viewingCurrentMatch={viewingCurrentMatch}
              matchInProgress={matchInProgress}
              fetchMatchInfo={fetchMatchInfo}
              selectedMatch={viewingCurrentMatch ? match : selectedMatch}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    matches: state.matches,
    selectedMatch: state.selectedMatch.match,
    currentMatch: state.currentMatch.match,
    viewingCurrentMatch: state.currentMatch.viewing,
    matchInProgress: state.currentMatch.inProgress
  };
};

const mapDispatchToProps = dispatch => ({
  requestCurrentMatch: tag => {
    dispatch(matchActions.requestCurrentMatch(tag));
  },
  fetchMatchInfo: matchId => dispatch(matchActions.requestMatchInfo(matchId))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Content)
);
