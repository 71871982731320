const status = (state = { sidebarOpen: false }, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { sidebarOpen: !state.sidebarOpen };
    default:
      return state;
  }
};

export default { status };
