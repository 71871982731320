import React, { Component } from "react";
import _ from "underscore";

import { playerIconKD } from "../../helpers/playerImage";
import statCalculators from "../../helpers/statCalculators";

class Player extends Component {
  renderBerryImage(player) {
    if (player.hasBerry) {
      return <img className="stream-player__berry" src={"/berry.png"} />
    }
  }
  renderWarriorImage(player) {
    if (player.type === "warrior") {
      return <img className="stream-player__sword" src={"/sword.png"} />
    }
  }
  renderSpeedImage(player) {
    if (player.hasSpeed) {
      return <img className="stream-player__speed" src={"/speed.png"} />
    }
  }
  renderQueenKills(queenKills) {
    return _.times(queenKills, (i) => {
      return <img className={`stream-player__crown stream-player__crown--${i}`} src={"/crown.png"} />
    })
  }

  render() {
    const { team, player, events } = this.props;

    const iconUrl = playerIconKD(player);
    const warriorKills = statCalculators.calculateKills(
      player.id,
      events,
      "warrior"
    );
    const queenKills = statCalculators.calculateKills(
      player.id,
      events,
      "queen"
    );
    const kills = warriorKills + queenKills;
    const warriorDeaths = statCalculators.calculateDeaths(
      player.id,
      events,
      "warrior"
    );
    const queenDeaths = statCalculators.calculateDeaths(
      player.id,
      events,
      "queen"
    );
    const deaths = warriorDeaths + queenDeaths;

    return (
      <div className={`stream-player stream-player--${team}`}>
        <img className="stream-player__icon" src={iconUrl} />
        <div className="stream-player__stats">
          <div className="stream-player__kills">{kills}</div>
          <div className="stream-player__deaths">{deaths}</div>
        </div>
        {this.renderBerryImage(player)}
        {this.renderSpeedImage(player)}
        {this.renderWarriorImage(player)}
        {this.renderQueenKills(queenKills)}
      </div>
    );
  }
}

export default Player;
