import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Link } from "react-router-dom";
import { matchActions } from "../actions";
import _ from "underscore";

import BerriesRemaining from "../components/stream/BerriesRemaining";
import BerriesUsed from "../components/stream/BerriesUsed";
import Team from "../components/stream/Team";
import LastMatchStats from "../components/stream/LastMatchStats";
import MatchMap from "../components/MatchMap";
import MatchTimer from "../components/stream/MatchTimer";
import Tournament from "../components/stream/Tournament";

import gameConstants from "../helpers/gameConstants";

class Stream extends React.Component {
  componentDidMount() {
    const {
      requestCurrentMatchByTag,
      requestLastMatchByTag,
      match: {
        params: { tag }
      }
    } = this.props;

    requestCurrentMatchByTag(tag);
    requestLastMatchByTag(tag);
  }

  componentDidUpdate() {
    const {
      socket,
      requestCurrentMatchByTag,
      match: {
        params: { tag }
      }
    } = this.props;
  }

  render() {
    const {
      socket,
      requestCurrentMatchByTag,
      currentMatchByTag,
      requestLastMatchByTag,
      lastMatchByTag
    } = this.props;

    let events, players;
    if (lastMatchByTag.data) {
      events = lastMatchByTag.data.events;
      players = lastMatchByTag.data.players;
    }

    return (
      <div className="stream-view">
        <Switch>
          <Route
            exact
            path="/stream/:tag"
            component={props => (
              <React.Fragment>
                <Link to={`/stream/${this.props.match.params.tag}/match_timer`}>
                  <div className="btn btn-primary">Match Timer</div>
                </Link>
                <Link
                  to={`/stream/${
                    this.props.match.params.tag
                  }/berries_remaining`}
                >
                  <div className="btn btn-primary">Berries Remaining</div>
                </Link>
                <Link
                  to={`/stream/${
                    this.props.match.params.tag
                  }/berries_used/blue`}
                >
                  <div className="btn btn-primary">Berries Used Blue</div>
                </Link>
                <Link
                  to={`/stream/${
                    this.props.match.params.tag
                  }/berries_used/gold`}
                >
                  <div className="btn btn-primary">Berries Used Gold</div>
                </Link>
                <Link to={`/stream/${this.props.match.params.tag}/blue_team`}>
                  <div className="btn btn-primary">Blue Team K/D</div>
                </Link>
                <Link to={`/stream/${this.props.match.params.tag}/gold_team`}>
                  <div className="btn btn-primary">Gold Team K/D</div>
                </Link>
                <Link
                  to={`/stream/${this.props.match.params.tag}/last_match_map`}
                >
                  <div className="btn btn-primary">Last Match Map Kills</div>
                </Link>
                <Link
                  to={`/stream/${this.props.match.params.tag}/team_info/gold/team`}
                >
                  <div className="btn btn-primary">Gold Team Name</div>
                </Link>
                <Link
                  to={`/stream/${this.props.match.params.tag}/team_info/gold/score`}
                >
                  <div className="btn btn-primary">Gold Team Score</div>
                </Link>
                <Link
                  to={`/stream/${this.props.match.params.tag}/team_info/blue/team`}
                >
                  <div className="btn btn-primary">Blue Team Name</div>
                </Link>
                <Link
                  to={`/stream/${this.props.match.params.tag}/team_info/blue/score`}
                >
                  <div className="btn btn-primary">Blue Team Score</div>
                </Link>
              </React.Fragment>
            )}
          />
          <Route
            path="/stream/:tag/team_info/:team/:value"
            component={props => <Tournament {...props} socket={socket} />}
          />
          <Route
            path="/stream/:tag/match_timer"
            component={props => (
              <MatchTimer
                {...props}
                socket={socket}
                requestCurrentMatchByTag={requestCurrentMatchByTag}
                currentMatch={currentMatchByTag}
              />
            )}
          />
          <Route
            path="/stream/:tag/berries_remaining"
            component={props => (
              <BerriesRemaining
                {...props}
                socket={socket}
                requestCurrentMatchByTag={requestCurrentMatchByTag}
                currentMatch={currentMatchByTag}
              />
            )}
          />
          <Route
            path="/stream/:tag/berries_used/:team?"
            component={props => (
              <BerriesUsed
                {...props}
                socket={socket}
                requestCurrentMatchByTag={requestCurrentMatchByTag}
                currentMatch={currentMatchByTag}
              />
            )}
          />
          <Route
            path="/stream/:tag/blue_team"
            component={props => (
              <Team
                {...props}
                team={"blue"}
                socket={socket}
                requestCurrentMatchByTag={requestCurrentMatchByTag}
                playerIds={gameConstants.playerIds("blue", false)}
                currentMatch={currentMatchByTag}
              />
            )}
          />
          <Route
            path="/stream/:tag/gold_team"
            component={props => (
              <Team
                {...props}
                team={"gold"}
                socket={socket}
                requestCurrentMatchByTag={requestCurrentMatchByTag}
                playerIds={gameConstants.playerIds("gold", false)}
                currentMatch={currentMatchByTag}
              />
            )}
          />
          <Route
            path="/stream/:tag/last_match_map/:ids?"
            component={props => (
              <MatchMap
                {...props}
                socket={socket}
                stream={true}
                players={players}
                events={events}
                map={lastMatchByTag.map}
                requestLastMatchByTag={requestLastMatchByTag}
              />
            )}
          />
          <Route
            path="/stream/:tag/last_match_stats"
            component={LastMatchStats}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentMatchByTag: state.currentMatchByTag.match,
    lastMatchByTag: state.lastMatchByTag.match
  };
};

const mapDispatchToProps = dispatch => ({
  requestCurrentMatchByTag: tag => {
    dispatch(matchActions.requestCurrentMatchByTag(tag));
  },
  requestLastMatchByTag: tag => {
    dispatch(matchActions.requestLastMatchByTag(tag));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Stream)
);
