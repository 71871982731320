import React from "react";
import { connect } from "react-redux";
import { sidebarActions, matchActions } from "../actions";

import GameSelector from "../components/GameSelector";

class Sidebar extends React.Component {
  componentDidMount() {
    const { match, fetchMatches } = this.props;

    fetchMatches(this.props.matchPage, match.params.tag);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillUnmount() {
    this.props.fetchMatches(null, null)
  }

  componentDidUpdate() {
    const {
      viewingCurrentMatch,
      matchInProgress,
      matches,
      selectedMatchId,
      currentMatchData,
      setInitialMatch
    } = this.props;

    if (
      matches.length > 0 &&
      !selectedMatchId &&
      !matchInProgress &&
      (!viewingCurrentMatch || !currentMatchData)
    ) {
      setInitialMatch(matches[0].id);
    }
  }

  handleScroll(e) {
    const {
      match,
      fetchMatches,
      matchPage,
      isFetchingMatches,
      allMatchesFetched
    } = this.props;
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop < clientHeight * 2 &&
      !allMatchesFetched &&
      !isFetchingMatches
    ) {
      fetchMatches(matchPage + 1, match.params.tag);
    }
  }

  render() {
    const {
      matches,
      selectedMatchId,
      sidebarOpen,
      selectMatch,
      viewingCurrentMatch,
      matchInProgress,
      viewCurrentMatch
    } = this.props;

    return (
      <div
        onScroll={this.handleScroll}
        style={styles.sidebar}
        className={"sidebar " + (sidebarOpen ? "open" : "")}
      >
        <GameSelector
          onViewCurrentMatch={viewCurrentMatch}
          onSelectGame={selectMatch}
          viewingCurrentMatch={viewingCurrentMatch}
          matchInProgress={matchInProgress}
          previousGames={matches}
          selectedGameId={selectedMatchId}
        />
      </div>
    );
  }
}

const styles = {
  sidebar: {
    backgroundColor: "black"
  }
};

const mapStateToProps = state => {
  return {
    sidebarOpen: state.sidebarStatus.sidebarOpen,
    isFetchingMatches: state.matches.isFetching,
    matches: state.matches.matches,
    matchPage: state.matches.page,
    allMatchesFetched: state.matches.allMatchesFetched,
    selectedMatchId: state.selectedMatch.id,
    viewingCurrentMatch: state.currentMatch.viewing,
    matchInProgress: state.currentMatch.inProgress,
    currentMatchData: state.currentMatch.match.data
  };
};

const mapDispatchToProps = dispatch => ({
  selectMatch: matchId => {
    dispatch(sidebarActions.toggleSidebar());
    dispatch(matchActions.requestMatch(matchId));
    dispatch(matchActions.stopViewingCurrentMatch());
  },
  setInitialMatch: matchId => {
    dispatch(matchActions.requestMatch(matchId));
    dispatch(matchActions.stopViewingCurrentMatch());
  },
  fetchMatches: (page, tag) => dispatch(matchActions.requestMatches(page, tag)),
  viewCurrentMatch: () => {
    dispatch(sidebarActions.toggleSidebar());
    dispatch(matchActions.unselectMatch());
    dispatch(matchActions.startViewingCurrentMatch());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
