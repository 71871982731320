import React, { Component } from "react";
import _ from "underscore";

export default class Event extends Component {
  generateName(player) {
    if (player) {
      const { slot, team, type, hasBerry, hasSpeed } = player;
      const status = _.filter(
        [hasSpeed ? "S" : "", hasBerry ? "B" : ""],
        el => {
          return el !== "";
        }
      );

      if (slot === "queen") {
        return (
          <span key={player.id} className={"capitalize " + team}>
            {team} {slot}
          </span>
        );
      } else {
        return (
          <span key={player.id} className={"capitalize " + team}>
            {team} {slot} {type}{" "}
            {status.length > 0 ? `(${status.join(",")})` : ""}
          </span>
        );
      }
    }
  }

  fixMapName(map) {
    if (map) {
      return `${map.replace("map_", "").replace("_", " ")} map`;
    }
  }

  handleEvent() {
    const { event, startTime } = this.props;
    let {
      time,
      type,
      player,
      victor,
      victim,
      team,
      map,
      winCondition,
      x,
      y,
      maiden,
      isHuman
    } = event;

    let playerName = this.generateName(player);
    let victorName = this.generateName(victor);
    let victimName = this.generateName(victim);

    map = this.fixMapName(map);

    let data = (() => {
      switch (type) {
        case "gamestart":
          return `Game started on ${map}`;
        case "gameend":
          return `Game ended on ${map}`;
        case "victory":
          return [
            <span key={team} className={"capitalize " + team}>
              {team}
            </span>,
            ` wins with ${winCondition}`
          ];
        case "spawn":
          return [playerName, ` spawned in as ${isHuman ? "human" : "bot"}`];
        case "playerKill":
          return [victorName, " killed ", victimName, ` at ${x}, ${y}`];
        case "glance":
          return [victorName, " bumped ", victimName];
        case "carryFood":
          return [playerName, " picked up a berry"];
        case "berryDeposit":
          return [playerName, ` deposited a berry at ${x}, ${y}`];
        case "berryKickIn":
          return [playerName, ` kicked in a berry from ${x}, ${y}`];
        case "getOnSnail":
          return [playerName, ` hopped on the snail at ${x}, ${y}`];
        case "getOffSnail":
          return [playerName, ` hopped off the snail at ${x}, ${y}`];
        case "snailEat":
          return [victorName, " is eating ", victimName, ` at ${x}, ${y}`];
        case "snailEscape":
          return [playerName, ` escaped being eaten at ${x}, ${y}`];
        case "blessMaiden":
          return [
            <span key={team} className={"capitalize " + team}>
              {team}
            </span>,
            ` tagged ${maiden.side} ${maiden.type} gate`
          ];
        case "reserveMaiden":
          //REFACTOR Fix to maiden bug
          if (maiden) {
            return [
              playerName,
              ` is standing in ${maiden.side} ${maiden.type} gate`
            ];
          }
          return [];
        case "unreserveMaiden":
          return [
            playerName,
            ` stopped standing in ${maiden.side} ${maiden.type} gate`
          ];
        case "useMaiden":
          //REFACTOR Fix to maiden bug
          if (maiden) {
            return [playerName, ` used ${maiden.side} ${maiden.type} gate`];
          }
          return [];
        default:
          return `Unknown event ${type}`;
      }
    })();

    return (
      <React.Fragment>
        <span className="c-game-event__time">{(time - startTime) / 1000}</span>
        <span className="c-game-event__data">{data}</span>
      </React.Fragment>
    );
  }

  render() {
    return <div className="c-game-event">{this.handleEvent()}</div>;
  }
}
