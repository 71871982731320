const REQUEST_MATCHES = "REQUEST_MATCHES";
const RECEIVE_MATCHES = "RECEIVE_MATCHES";
const REQUEST_MATCH = "REQUEST_MATCH";
const RECEIVE_MATCH = "RECEIVE_MATCH";
const REQUEST_MATCH_INFO = "REQUEST_MATCH_INFO";
const RECEIVE_MATCH_INFO = "RECEIVE_MATCH_INFO";
const REQUEST_CURRENT_MATCH = "REQUEST_CURRENT_MATCH";
const RECEIVE_CURRENT_MATCH = "RECEIVE_CURRENT_MATCH";
const REQUEST_CURRENT_MATCH_BY_TAG = "REQUEST_CURRENT_MATCH_BY_TAG";
const RECEIVE_CURRENT_MATCH_BY_TAG = "RECEIVE_CURRENT_MATCH_BY_TAG";
const REQUEST_LAST_MATCH_BY_TAG = "REQUEST_LAST_MATCH_BY_TAG";
const RECEIVE_LAST_MATCH_BY_TAG = "RECEIVE_LAST_MATCH_BY_TAG";
const SET_CURRENT_MATCH_IN_PROGRESS = "SET_CURRENT_MATCH_IN_PROGRESS";
const START_VIEWING_CURRENT_MATCH = "START_VIEWING_CURRENT_MATCH";
const STOP_VIEWING_CURRENT_MATCH = "STOP_VIEWING_CURRENT_MATCH";
const SET_INITIAL_MATCH = "SET_INITIAL_MATCH";
const UNSELECT_MATCH = "UNSELECT_MATCH";

function requestMatches(page, tag) {
  return {
    type: REQUEST_MATCHES,
    payload: { page, tag }
  };
}

function receiveMatches(matches) {
  return { type: RECEIVE_MATCHES, payload: { matches } };
}

function requestMatch(matchId) {
  return {
    type: REQUEST_MATCH,
    payload: { matchId }
  };
}

function receiveMatch(match) {
  return {
    type: RECEIVE_MATCH,
    payload: { match }
  };
}

function requestCurrentMatch(tag) {
  return {
    type: REQUEST_CURRENT_MATCH,
    payload: { tag }
  };
}

function receiveCurrentMatch(match) {
  return {
    type: RECEIVE_CURRENT_MATCH,
    payload: { match }
  };
}

function requestCurrentMatchByTag(tag) {
  return {
    type: REQUEST_CURRENT_MATCH_BY_TAG,
    payload: { tag }
  };
}

function receiveCurrentMatchByTag(match) {
  return {
    type: RECEIVE_CURRENT_MATCH_BY_TAG,
    payload: { match }
  };
}

function requestLastMatchByTag(tag) {
  return {
    type: REQUEST_LAST_MATCH_BY_TAG,
    payload: { tag }
  };
}

function receiveLastMatchByTag(match) {
  return {
    type: RECEIVE_LAST_MATCH_BY_TAG,
    payload: { match }
  };
}

function requestMatchInfo(matchId) {
  return {
    type: REQUEST_MATCH_INFO,
    payload: { matchId }
  };
}

function receiveMatchInfo(matchInfo) {
  return {
    type: RECEIVE_MATCH_INFO,
    payload: { matchInfo }
  };
}

function setCurrentMatchInProgress() {
  return {
    type: SET_CURRENT_MATCH_IN_PROGRESS
  };
}

function startViewingCurrentMatch() {
  return {
    type: START_VIEWING_CURRENT_MATCH
  };
}

function stopViewingCurrentMatch() {
  return {
    type: STOP_VIEWING_CURRENT_MATCH
  };
}

function unselectMatch() {
  return {
    type: UNSELECT_MATCH
  };
}

function setInitialMatch() {
  return {
    type: SET_INITIAL_MATCH
  };
}

export default {
  requestMatches,
  receiveMatches,
  requestMatch,
  receiveMatch,
  requestCurrentMatch,
  receiveCurrentMatch,
  requestCurrentMatchByTag,
  receiveCurrentMatchByTag,
  requestLastMatchByTag,
  receiveLastMatchByTag,
  requestMatchInfo,
  receiveMatchInfo,
  setCurrentMatchInProgress,
  startViewingCurrentMatch,
  stopViewingCurrentMatch,
  unselectMatch,
  setInitialMatch,
  REQUEST_MATCHES,
  RECEIVE_MATCHES,
  REQUEST_MATCH,
  RECEIVE_MATCH,
  REQUEST_MATCH_INFO,
  RECEIVE_MATCH_INFO,
  REQUEST_CURRENT_MATCH,
  RECEIVE_CURRENT_MATCH,
  REQUEST_CURRENT_MATCH_BY_TAG,
  RECEIVE_CURRENT_MATCH_BY_TAG,
  REQUEST_LAST_MATCH_BY_TAG,
  RECEIVE_LAST_MATCH_BY_TAG,
  SET_CURRENT_MATCH_IN_PROGRESS,
  START_VIEWING_CURRENT_MATCH,
  STOP_VIEWING_CURRENT_MATCH,
  UNSELECT_MATCH,
  SET_INITIAL_MATCH
};
