import React, { Component } from "react";
import _ from "underscore";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlayers: []
    };
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selectedPlayers } = this.state;
    if (prevProps.events !== this.props.events) {
      this.props.onFilter({
        events: this.filterEvents(),
        players: selectedPlayers
      });
    }
  }

  handleFilter(id) {
    let { selectedPlayers } = this.state;

    if (_.contains(selectedPlayers, id)) {
      selectedPlayers = _.without(selectedPlayers, id);
    } else {
      selectedPlayers.push(id);
    }

    this.setState({ selectedPlayers }, () => {
      this.props.onFilter({
        events: this.filterEvents(),
        players: selectedPlayers
      });
    });
  }

  getPlayerImage(player) {
    const { team, slot, type } = player;

    if (type === "queen") {
      return `/players/${team}-${slot}.png`;
    } else {
      if (type === "bot") {
        return `/players/${team}-${type}.png`;
      } else {
        return `/players/${team}-drone-${slot}.png`;
      }
    }
  }

  filterEvents() {
    const { events } = this.props;
    const { selectedPlayers } = this.state;

    let filteredEvents = events;

    if (selectedPlayers.length > 0) {
      filteredEvents = _.filter(events, event => {
        const { type, team, player, victor, victim } = event;

        return (
          type === "gamestart" ||
          type === "gameend" ||
          type === "victory" ||
          (selectedPlayers.includes(1) &&
            type === "blessMaiden" &&
            team === "gold") ||
          (selectedPlayers.includes(2) &&
            type === "blessMaiden" &&
            team === "blue") ||
          (player && selectedPlayers.includes(player.id)) ||
          (victor && selectedPlayers.includes(victor.id)) ||
          (victim && selectedPlayers.includes(victim.id))
        );
      });
    }

    return filteredEvents;
  }

  render() {
    const { players } = this.props;
    const { selectedPlayers } = this.state;

    const filterComponents = _.map(players, player => {
      const { id, team } = player;

      return (
        <div
          key={id}
          className={
            team + " " + (selectedPlayers.includes(id) ? "selected" : "")
          }
          onClick={this.handleFilter.bind(null, id)}
        >
          <img alt={player.id} src={this.getPlayerImage(player)} />
        </div>
      );
    });

    return <div className="log-filter">{filterComponents}</div>;
  }
}
