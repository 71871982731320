import _ from "underscore";
// Potential player stats object shape
// {
//   playerId: {
//     kills: {
//       playerId: {
//         asSpeedWarrior: {
//           speedWarrior: 0,
//           warrior: 0,
//           speedDrone:0,
//           drone: 0,
//           bot: 0,
//         },
//         asWarrior: {
//           speedWarrior: 0,
//           warrior: 0,
//           speedDrone:0,
//           drone: 0,
//           bot: 0,
//         },
//         asSpeedDrone: 0,
//         warrior: 0,
//         speed: 0,
//         drone: 0,
//       }
//     },
//     deaths: {
//
//     },
//     assists: {
//
//     },
//     bumps: {
//
//     },
//     uptime: {
//
//     },
//     gates: {
//
//     },
//     berries: {
//
//     },
//     snail: {
//
//     }
//   }
// }

function calculateAssists(events) {
  let assists = {};

  _.times(10, i => {
    assists[i + 1] = {
      total: 0,
      queen: 0,
      warrior: 0,
      drone: 0,
      bot: 0
    };
  });

  const test = _.map(events, event => {
    const { type } = event;

    if (type === "playerKill") {
      const { time, victor: killVictor, victim: killVictim } = event;

      let recentEvents = _.filter(events, event => {
        const { type } = event;
        let timeDiff = time - event.time;
        if (type === "glance" && timeDiff > 0 && timeDiff < 2000) {
          const { victor, victim } = event;
          // REFACTOR: Issue with victor and victim sometimes not
          // showing up for glances in newer KQ version
          if (victor && victim) {
            return (
              (victim.id === killVictim.id || victor.id === killVictim.id) &&
              (victim.id !== killVictor.id && victor.id !== killVictor.id)
            );
          }
        }
      });

      let alreadyAwarded = [];
      const assistingPlayers = _.map(recentEvents, event => {
        if (
          event.victor.team === killVictor.team &&
          !alreadyAwarded.includes(event.victor.id)
        ) {
          assists[event.victor.id][event.victim.type] += 1;
          assists[event.victor.id]["total"] += 1;
          alreadyAwarded.push(event.victor.id);
        } else if (!alreadyAwarded.includes(event.victim.id)) {
          assists[event.victim.id][event.victor.type] += 1;
          assists[event.victim.id]["total"] += 1;
          alreadyAwarded.push(event.victim.id);
        }
      });
    }
  });

  return assists;
}

// TODO: Eventually make it estimate ending distance on non snail victory.
// based on current riders speed status and team.
// Add goldOnLeft support
function calculateSnailStats(events) {
  let snailStats = {};

  // Only 8 since queens can't ride snail
  _.times(8, i => {
    snailStats[i + 3] = {
      distance: 0
    };
  });

  const snailEvents = _.filter(events, event => {
    const { type } = event;
    return ["getOnSnail", "getOffSnail", "snailEat", "victory"].includes(type);
  });

  let lastSnailPosition = 960;
  let lastSnailRider = null;

  _.each(snailEvents, event => {
    const { type, winCondition, team, x, player, victor } = event;
    if (type === "victory") {
      if (winCondition === "snail") {
        const goldOnLeft = false;
        let finalSnailPosition = 0;
        if (team === "gold") {
          finalSnailPosition = goldOnLeft ? 0 : 1920;
        } else {
          finalSnailPosition = goldOnLeft ? 1920 : 0;
        }

        snailStats[lastSnailRider]["distance"] += Math.abs(
          lastSnailPosition - finalSnailPosition
        );
      }
    } else {
      const playerId = player ? player.id : victor.id;
      snailStats[playerId]["distance"] += Math.abs(lastSnailPosition - x);
      lastSnailRider = playerId;
      lastSnailPosition = x;
    }
  });

  return snailStats;
}

function calculateWarriorUptime(events) {
  let warriorUptime = {};

  // Only 8 since queens can't become warriors
  _.times(8, i => {
    warriorUptime[i + 3] = {
      duration: 0,
      lastTimestamp: 0,
      isWarrior: false
    };
  });

  const warriorEvents = _.filter(events, event => {
    const { type } = event;
    return ["useMaiden", "playerKill", "victory"].includes(type);
  });

  _.each(warriorEvents, event => {
    const { time, type, player, victim, maiden } = event;

    switch (type) {
      case "useMaiden":
        if (maiden.type === "warrior") {
          warriorUptime[player.id]["isWarrior"] = true;
          warriorUptime[player.id]["lastTimestamp"] = time;
        }
        break;
      case "playerKill":
        if (victim.type === "warrior") {
          warriorUptime[victim.id]["isWarrior"] = false;
          warriorUptime[victim.id]["duration"] +=
            time - warriorUptime[victim.id]["lastTimestamp"];
        }
        break;
      case "victory":
        _.each(warriorUptime, uptime => {
          if (uptime.isWarrior) {
            uptime.duration += time - uptime.lastTimestamp;
          }
        });
        break;
      default:
        break;
    }
  });

  return warriorUptime;
}

function calculateSpeedUptime(events) {
  let speedUptime = {};

  // Only 8 since queens can't become warriors
  _.times(8, i => {
    speedUptime[i + 3] = {
      duration: 0,
      lastTimestamp: 0,
      hasSpeed: false
    };
  });

  const speedEvents = _.filter(events, event => {
    const { type } = event;
    return ["useMaiden", "playerKill", "victory"].includes(type);
  });

  _.each(speedEvents, event => {
    const { time, type, player, victim, maiden } = event;

    switch (type) {
      case "useMaiden":
        if (maiden.type === "speed") {
          speedUptime[player.id]["hasSpeed"] = true;
          speedUptime[player.id]["lastTimestamp"] = time;
        }
        break;
      case "playerKill":
        if (victim.type === "speed") {
          speedUptime[victim.id]["hasSpeed"] = false;
          speedUptime[victim.id]["duration"] +=
            time - speedUptime[victim.id]["lastTimestamp"];
        }
        break;
      case "victory":
        _.each(speedUptime, uptime => {
          if (uptime.hasSpeed) {
            uptime.duration += time - uptime.lastTimestamp;
          }
        });
        break;
      default:
        break;
    }
  });

  return speedUptime;
}

function calculateStatusUptime(events) {
  let statusUptime = {};

  _.times(10, i => {
    statusUptime[i + 1] = {
      speedWarriorUptime: 0,
      warriorUptime: 0,
      speedUptime: 0,
      snailUptime: 0,
      hasSpeed: false,
      isWarrior: false,
      isRidingSnail: false,
      berriesPickedUp: 0,
      berriesDeposited: 0,
      berriesKickedIn: 0,
      speedGatesUsed: 0,
      speedGateAttempts: 0,
      warriorGatesUsed: 0,
      warriorGateAttempts: 0
    };
  });

  let lastEventTime = 0;
  _.each(events, event => {
    const { time, type, player, victim, maiden } = event;

    let playerId = null;
    if (
      [
        "useMaiden",
        "unreserveMaiden",
        "playerKill",
        "getOnSnail",
        "carryFood",
        "berryDeposit",
        "berryKickIn"
      ].includes(type)
    ) {
      playerId = player ? player.id : victim.id;
    }

    _.each(statusUptime, playerUptime => {
      addUptime(playerUptime, time, lastEventTime);
    });

    switch (type) {
      case "useMaiden":
        // REFACTOR Somehow a match broke due to this. Check maidens on matches
        if (maiden) {
          if (maiden.type === "speed") {
            statusUptime[playerId]["hasSpeed"] = true;
            statusUptime[playerId]["speedGatesUsed"] += 1;
          }
          if (maiden.type === "warrior") {
            statusUptime[playerId]["isWarrior"] = true;
            statusUptime[playerId]["warriorGatesUsed"] += 1;
          }
        }
        break;
      case "unreserveMaiden":
        if (maiden) {
          switch (maiden.type) {
            case "speed":
              statusUptime[playerId]["speedGateAttempts"] += 1;
              break;
            case "warrior":
              statusUptime[playerId]["warriorGateAttempts"] += 1;
              break;
            default:
              break;
          }
        }
      case "carryFood":
        statusUptime[playerId]["berriesPickedUp"] += 1;
        break;
      case "berryDeposit":
        statusUptime[playerId]["berriesDeposited"] += 1;
        break;
      case "berryKickIn":
        statusUptime[playerId]["berriesKickedIn"] += 1;
        break;
      case "getOnSnail":
        statusUptime[playerId]["isRidingSnail"] = true;
        break;
      case "playerKill":
        statusUptime[playerId]["hasSpeed"] = false;
        statusUptime[playerId]["isWarrior"] = false;
        statusUptime[playerId]["isRidingSnail"] = false;
        break;
      default:
        break;
    }

    lastEventTime = time;
  });

  return statusUptime;
}

function addUptime(playerUptime, time, lastEventTime) {
  const timeDiff = time - lastEventTime;

  if (playerUptime.hasSpeed && playerUptime.isWarrior)
    playerUptime.speedWarriorUptime += timeDiff;
  if (playerUptime.isWarrior) playerUptime.warriorUptime += timeDiff;
  if (playerUptime.hasSpeed) playerUptime.speedUptime += timeDiff;
  if (playerUptime.isRidingSnail) playerUptime.snailUptime += timeDiff;

  return playerUptime;
}

function calculateMaidenTags(events) {}

const calculateKills = (id, events, type) => {
  return _.reduce(
    events,
    (kills, event) => {
      if (event.type === "playerKill" && event.victor.id === id) {
        if (event.victim.type === type) {
          return kills + 1;
        } else if (type === "all") {
          return kills + 1;
        }
        return kills;
      } else {
        return kills;
      }
    },
    0
  );
};

const calculateDeaths = (id, events, type) => {
  return _.reduce(
    events,
    (deaths, event) => {
      if (event.type === "playerKill" && event.victim.id === id) {
        if (event.victim.type === type) {
          return deaths + 1;
        } else if (type === "all") {
          return deaths + 1;
        }
        return deaths;
      } else {
        return deaths;
      }
    },
    0
  );
};

const berryCount = (events) => {
  return _.reduce(
    events,
    (berries, event) => {
      let key = "unknown";
      const {
        type, 
        isOwnHive,
      } = event;

      if (event.player) {
        const {player: { team, id }} = event
        let playerBerries = berries[id] || {gold: 0, blue: 0, unknown: 0}

        switch (type) {
          case "berryDeposit":
            key = team;
            return {
              ...berries,
              [key]: berries[key] + 1,
              [id]: {
                ...playerBerries,
                [key]: playerBerries[key] + 1
              }
            };
          case "berryKickIn":
            if (isOwnHive === true) {
              key = team;
            } else if (isOwnHive === false) {
              key = team === "blue" ? "gold" : "blue";
            }
            return {
              ...berries,
              [key]: berries[key] + 1,
              [id]: {
                ...playerBerries,
                [key]: playerBerries[key] + 1
              }
            };
        }
      }
      return berries;
    },
    { gold: 0, blue: 0, unknown: 0 }
  );
};

const berriesUsed = (events) => {
  return _.reduce(
    events,
    (berries, event) => {
      let key = "unknown";
      const {
        type, 
        isOwnHive,
      } = event;

      if (event.player) {
        const {player: { team, id }} = event
        let playerBerries = berries[id] || {gold: 0, blue: 0, unknown: 0}

        switch (type) {
          case "berryDeposit":
            key = team;
            return {
              ...berries,
              [key]: berries[key] + 1,
              [id]: {
                ...playerBerries,
                [key]: playerBerries[key] + 1
              }
            };
          case "berryKickIn":
            if (isOwnHive === true) {
              key = team;
            } else if (isOwnHive === false) {
              key = team === "blue" ? "gold" : "blue";
            }
            return {
              ...berries,
              [key]: berries[key] + 1,
              [id]: {
                ...playerBerries,
                [key]: playerBerries[key] + 1
              }
            };
          case "useMaiden":
            key = team;
            return {
              ...berries,
              [key]: berries[key] + 1,
              [id]: {
                ...playerBerries,
                [key]: playerBerries[key] + 1
              }
            };
        }
      }
      return berries;
    },
    { gold: 0, blue: 0, unknown: 0 }
  );
};

export default {
  calculateAssists,
  calculateSnailStats,
  calculateWarriorUptime,
  calculateSpeedUptime,
  calculateStatusUptime,
  calculateMaidenTags,
  calculateKills,
  calculateDeaths,
  berryCount,
  berriesUsed
};
