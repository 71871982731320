import React from "react";
import { connect } from "react-redux";
import { sidebarActions } from "../actions";
import { Link } from "react-router-dom";

const Header = props => {
  const { toggleSidebar, cabinetStatus, piStatus } = props;

  return (
    <header>
      <img
        alt={"baltimorebee"}
        onClick={toggleSidebar}
        className="logo"
        src="/baltimorebee.png"
      />
      <Link to="/">
        <div className="title">BmoreKQ</div>
      </Link>
    </header>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(sidebarActions.toggleSidebar())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
