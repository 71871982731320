import React, { Component } from "react";

import MatchStats from "./MatchStats.js";
import MatchGraphs from "./MatchGraphs.js";
import MatchMap from "./MatchMap.js";
import MatchLog from "./MatchLog.js";

import Filter from "./Filter.js";

import messageHelper from "../helpers/messageHelper";

import _ from "underscore";

export default class GameDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "stats",
      filteredEvents: [],
      filteredPlayers: []
    };

    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    const {
      requestCurrentMatch,
      match: {
        params: { tag }
      }
    } = this.props;
    requestCurrentMatch(tag);
    this.setWebsocketEvents();
  }
  componentDidUpdate() {
    this.setWebsocketEvents();
  }

  componentWillUnmount() {
    this.props.socket.onmessage = null
  }

  setWebsocketEvents() {
    const {
      socket,
      requestCurrentMatch,
      fetchMatchInfo,
      viewingCurrentMatch,
      matchInProgress,
      match: {
        params: { tag }
      }
    } = this.props;

    if (socket) {
      socket.onmessage = event => {
        const parsedMessage = messageHelper.parseMessage(event.data);

        switch (parsedMessage.type) {
          case "gamestart":
            requestCurrentMatch(tag);
            break;
          case "matchSaved":
            fetchMatchInfo(parsedMessage.values[0]);
            break;
          default:
            break;
        }

        if (viewingCurrentMatch && matchInProgress) {
          requestCurrentMatch(tag);
        }
      };
    }
  }

  renderComponent() {
    const { isLoading, goldOnLeft, selectedMatch: game } = this.props;

    if (isLoading || game === null || _.isEmpty(game)) return null;

    const { selectedTab, filteredEvents, filteredPlayers } = this.state;

    let { startTime, map, players, events } = game.data;

    const playerOrder = goldOnLeft
      ? [3, 5, 1, 7, 9, 4, 6, 2, 8, 10]
      : [4, 6, 2, 8, 10, 3, 5, 1, 7, 9];

    players.sort(function(a, b) {
      return playerOrder.indexOf(a.id) - playerOrder.indexOf(b.id);
    });

    switch (selectedTab) {
      case "stats":
        return (
          <MatchStats
            match={game}
            goldOnLeft={goldOnLeft}
            playerOrder={
              filteredPlayers.length > 0 ? filteredPlayers : playerOrder
            }
          />
        );
      case "graphs":
        return <MatchGraphs match={game} goldOnLeft={goldOnLeft} />;
      case "charts":
        return <div className="charts">Charts coming soon</div>;
      case "map":
        return (
          <MatchMap
            map={map}
            players={players}
            events={filteredEvents.length > 0 ? filteredEvents : events}
            goldOnLeft={goldOnLeft}
          />
        );
      case "log":
        return (
          <MatchLog
            players={players}
            events={filteredEvents.length > 0 ? filteredEvents : events}
            startTime={startTime}
          />
        );
      case "awards":
        return <div className="awards">Awards coming soon</div>;
      default:
        return <div>Something bad happened</div>;
    }
  }

  handleSelectTab(selectedTab) {
    this.setState({ selectedTab });
  }

  handleFilter(filteredData) {
    const { events: filteredEvents, players: filteredPlayers } = filteredData;
    this.setState({ filteredEvents, filteredPlayers });
  }

  render() {
    const { isLoading, goldOnLeft, selectedMatch: game } = this.props;
    // const { game } = this.state;
    if (isLoading || game === null || _.isEmpty(game)) return null;
    const { handleSelectTab } = this;
    const { selectedTab } = this.state;

    let { players, events } = game.data;

    const playerOrder = goldOnLeft
      ? [3, 5, 1, 7, 9, 4, 6, 2, 8, 10]
      : [4, 6, 2, 8, 10, 3, 5, 1, 7, 9];

    players.sort(function(a, b) {
      return playerOrder.indexOf(a.id) - playerOrder.indexOf(b.id);
    });

    const tabNames = ["stats", "graphs", "map", "log", "awards"];
    const tabs = _.map(tabNames, tab => {
      return (
        <li
          className={selectedTab === tab ? "selected" : ""}
          onClick={handleSelectTab.bind(null, tab)}
          key={tab}
        >
          {tab}
        </li>
      );
    });

    return (
      <div className="game-details">
        <ul className="tabs">{tabs}</ul>
        {this.renderComponent()}
        <Filter
          events={events}
          players={players}
          onFilter={this.handleFilter}
        />
      </div>
    );
  }
}
