import React, { Component } from "react";
import _ from "underscore";
import moment from "moment";

// REFACTOR: Use redux for the match fetching. Redux will need an action
// that fetches all matches. Or when no page is passed in
class OverallStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: []
    };
  }

  componentDidMount() {
    fetch("/api/matches")
      .then(res => res.json())
      .then(matches => {
        this.setState({ matches });
      });
  }

  calculateStats(options = {}) {
    let { matches } = this.state;
    let { date } = options;

    if (date) {
      date.set({
        hour: 6,
        minute: 0,
        second: 0
      });
      let lowerDate = date;
      let upperDate = date.clone().add(1, "days");

      matches = _.filter(matches, match => {
        let startTime = Date.parse(match.startTime);
        return startTime > lowerDate && startTime < upperDate;
      });
    }

    const blueWins = _.where(matches, { winningTeam: "blue" });
    const goldWins = _.where(matches, { winningTeam: "gold" });
    const military = _.where(matches, { winCondition: "military" });
    const snail = _.where(matches, { winCondition: "snail" });
    const economic = _.where(matches, { winCondition: "economic" });
    const dayMatches = _.where(matches, { map: "map_day" });
    const nightMatches = _.where(matches, { map: "map_night" });
    const duskMatches = _.where(matches, { map: "map_dusk" });

    return {
      date: options.date || "Overall",
      overall: {
        count: matches.length,
        military: military.length,
        snail: snail.length,
        economic: economic.length,
        blue: {
          count: blueWins.length,
          military: _.intersection(blueWins, military).length,
          snail: _.intersection(blueWins, snail).length,
          economic: _.intersection(blueWins, economic).length
        },
        gold: {
          count: goldWins.length,
          military: _.intersection(goldWins, military).length,
          snail: _.intersection(goldWins, snail).length,
          economic: _.intersection(goldWins, economic).length
        }
      },
      day: {
        count: dayMatches.length,
        military: _.intersection(dayMatches, military).length,
        snail: _.intersection(dayMatches, snail).length,
        economic: _.intersection(dayMatches, economic).length,
        blue: {
          count: _.intersection(dayMatches, blueWins).length,
          military: _.intersection(dayMatches, blueWins, military).length,
          snail: _.intersection(dayMatches, blueWins, snail).length,
          economic: _.intersection(dayMatches, blueWins, economic).length
        },
        gold: {
          count: _.intersection(dayMatches, goldWins).length,
          military: _.intersection(dayMatches, goldWins, military).length,
          snail: _.intersection(dayMatches, goldWins, snail).length,
          economic: _.intersection(dayMatches, goldWins, economic).length
        }
      },
      night: {
        count: nightMatches.length,
        military: _.intersection(nightMatches, military).length,
        snail: _.intersection(nightMatches, snail).length,
        economic: _.intersection(nightMatches, economic).length,
        blue: {
          count: _.intersection(nightMatches, blueWins).length,
          military: _.intersection(nightMatches, blueWins, military).length,
          snail: _.intersection(nightMatches, blueWins, snail).length,
          economic: _.intersection(nightMatches, blueWins, economic).length
        },
        gold: {
          count: _.intersection(nightMatches, goldWins).length,
          military: _.intersection(nightMatches, goldWins, military).length,
          snail: _.intersection(nightMatches, goldWins, snail).length,
          economic: _.intersection(nightMatches, goldWins, economic).length
        }
      },
      dusk: {
        count: duskMatches.length,
        military: _.intersection(duskMatches, military).length,
        snail: _.intersection(duskMatches, snail).length,
        economic: _.intersection(duskMatches, economic).length,
        blue: {
          count: _.intersection(duskMatches, blueWins).length,
          military: _.intersection(duskMatches, blueWins, military).length,
          snail: _.intersection(duskMatches, blueWins, snail).length,
          economic: _.intersection(duskMatches, blueWins, economic).length
        },
        gold: {
          count: _.intersection(duskMatches, goldWins).length,
          military: _.intersection(duskMatches, goldWins, military).length,
          snail: _.intersection(duskMatches, goldWins, snail).length,
          economic: _.intersection(duskMatches, goldWins, economic).length
        }
      }
    };
  }

  statsComponent(opts = {}) {
    const { date } = opts;

    const stats = this.calculateStats({ date: date });

    return (
      <div key={date} className="section">
        <div className="header">
          {date ? date.format("llll") : "Overall"} Stats
        </div>
        <div className="details">
          <div className="section">
            <div>Overall</div>
            <div>Total: {stats.overall.count}</div>
            <div>Day: {stats.day.count}</div>
            <div>Night: {stats.night.count}</div>
            <div>Dusk: {stats.dusk.count}</div>
            <div>Military: {stats.overall.military}</div>
            <div>Economic: {stats.overall.economic}</div>
            <div>Snail: {stats.overall.snail}</div>
            <div>Blue: {stats.overall.blue.count}</div>
            <div>Blue Military: {stats.overall.blue.military}</div>
            <div>Blue Economic: {stats.overall.blue.economic}</div>
            <div>Blue Snail: {stats.overall.blue.snail}</div>
            <div>Gold: {stats.overall.gold.count}</div>
            <div>Gold Military: {stats.overall.gold.military}</div>
            <div>Gold Economic: {stats.overall.gold.economic}</div>
            <div>Gold Snail: {stats.overall.gold.snail}</div>
          </div>
          <div className="section">
            <div>Day</div>
            <div>Total: {stats.day.count}</div>
            <div>Military: {stats.day.military}</div>
            <div>Economic: {stats.day.economic}</div>
            <div>Snail: {stats.day.snail}</div>
            <div>Blue: {stats.day.blue.count}</div>
            <div>Blue Military: {stats.day.blue.military}</div>
            <div>Blue Economic: {stats.day.blue.economic}</div>
            <div>Blue Snail: {stats.day.blue.snail}</div>
            <div>Gold: {stats.day.gold.count}</div>
            <div>Gold Military: {stats.day.gold.military}</div>
            <div>Gold Economic: {stats.day.gold.economic}</div>
            <div>Gold Snail: {stats.day.gold.snail}</div>
          </div>
          <div className="section">
            <div>Night</div>
            <div>Total: {stats.night.count}</div>
            <div>Military: {stats.night.military}</div>
            <div>Economic: {stats.night.economic}</div>
            <div>Snail: {stats.night.snail}</div>
            <div>Blue: {stats.night.blue.count}</div>
            <div>Blue Military: {stats.night.blue.military}</div>
            <div>Blue Economic: {stats.night.blue.economic}</div>
            <div>Blue Snail: {stats.night.blue.snail}</div>
            <div>Gold: {stats.night.gold.count}</div>
            <div>Gold Military: {stats.night.gold.military}</div>
            <div>Gold Economic: {stats.night.gold.economic}</div>
            <div>Gold Snail: {stats.night.gold.snail}</div>
          </div>
          <div className="section">
            <div>Dusk</div>
            <div>Total: {stats.dusk.count}</div>
            <div>Military: {stats.dusk.military}</div>
            <div>Economic: {stats.dusk.economic}</div>
            <div>Snail: {stats.dusk.snail}</div>
            <div>Blue: {stats.dusk.blue.count}</div>
            <div>Blue Military: {stats.dusk.blue.military}</div>
            <div>Blue Economic: {stats.dusk.blue.economic}</div>
            <div>Blue Snail: {stats.dusk.blue.snail}</div>
            <div>Gold: {stats.dusk.gold.count}</div>
            <div>Gold Military: {stats.dusk.gold.military}</div>
            <div>Gold Economic: {stats.dusk.gold.economic}</div>
            <div>Gold Snail: {stats.dusk.gold.snail}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { matches } = this.state;

    if (_.isEmpty(matches)) return null;

    let statsComponents = [];
    for (let i = 0; i < 7; i++) {
      statsComponents.push(
        this.statsComponent({ date: moment().subtract(i, "days") })
      );
    }

    return (
      <div className="page overall-stats">
        {this.statsComponent()}
        {statsComponents}
      </div>
    );
  }
}

export default OverallStats;
