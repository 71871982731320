function parseMessage(message) {
  const time = parseInt(message);
  const parsedMessage = message.match(/!\[k\[(.*?)\],v\[(.*)?\]\]!/);
  const type = parsedMessage[1];
  const values = parsedMessage[2].split(",");

  return { time, type, values };
}

export default {
  parseMessage
};
