import { matchActions } from "../actions";

const {
  REQUEST_MATCHES,
  RECEIVE_MATCHES,
  REQUEST_MATCH_INFO,
  RECEIVE_MATCH_INFO,
  REQUEST_MATCH,
  RECEIVE_MATCH,
  REQUEST_CURRENT_MATCH,
  RECEIVE_CURRENT_MATCH,
  REQUEST_CURRENT_MATCH_BY_TAG,
  RECEIVE_CURRENT_MATCH_BY_TAG,
  REQUEST_LAST_MATCH_BY_TAG,
  RECEIVE_LAST_MATCH_BY_TAG,
  SET_CURRENT_MATCH_IN_PROGRESS,
  START_VIEWING_CURRENT_MATCH,
  STOP_VIEWING_CURRENT_MATCH,
  UNSELECT_MATCH
} = matchActions;

function matches(
  state = {
    isFetching: false,
    isFetchingRecent: false,
    allMatchesFetched: false,
    matches: [],
    page: 1
  },
  action
) {
  switch (action.type) {
    case REQUEST_MATCHES:
      return {
        ...state,
        isFetching: true,
        page: action.payload.page,
        tag: action.payload.tag
      };
    case RECEIVE_MATCHES:
      return {
        ...state,
        isFetching: false,
        page: state.page,
        allMatchesFetched: action.payload.matches.length < 50,
        matches: [...state.matches, ...action.payload.matches]
      };
    case REQUEST_MATCH_INFO:
      return {
        ...state,
        isFetchingRecent: true
      };
    case RECEIVE_MATCH_INFO:
      return {
        ...state,
        isFetchingRecent: false,
        matches: [action.payload.matchInfo, ...state.matches]
      };
    default:
      return state;
  }
}

function match(state = { isFetching: false, match: null, id: null }, action) {
  switch (action.type) {
    case REQUEST_MATCH:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_MATCH:
      return {
        ...state,
        isFetching: false,
        match: action.payload.match,
        id: action.payload.match.id
      };
    case UNSELECT_MATCH:
      return {
        ...state,
        id: null
      };
    default:
      return state;
  }
}

function currentMatch(
  state = {
    isFetching: false,
    viewing: true,
    inProgress: false,
    match: { data: null }
  },
  action
) {
  switch (action.type) {
    case REQUEST_CURRENT_MATCH:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_CURRENT_MATCH:
      const { data: matchData } = action.payload.match;
      return {
        ...state,
        isFetching: false,
        match: action.payload.match,
        inProgress: matchData !== null && !matchData.winCondition
      };
    case SET_CURRENT_MATCH_IN_PROGRESS:
      return {
        ...state,
        inProgress: true
      };
    case START_VIEWING_CURRENT_MATCH:
      return {
        ...state,
        viewing: true
      };
    case STOP_VIEWING_CURRENT_MATCH:
      return {
        ...state,
        viewing: false
      };
    default:
      return state;
  }
}

function currentMatchByTag(
  state = {
    isFetching: false,
    inProgress: false,
    match: { data: null }
  },
  action
) {
  switch (action.type) {
    case REQUEST_CURRENT_MATCH_BY_TAG:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_CURRENT_MATCH_BY_TAG:
      const { data: matchData } = action.payload.match;
      return {
        ...state,
        isFetching: false,
        match: action.payload.match,
        inProgress: matchData !== null && !matchData.winCondition
      };
    default:
      return state;
  }
}

function lastMatchByTag(
  state = {
    isFetching: false,
    inProgress: false,
    match: { data: null }
  },
  action
) {
  switch (action.type) {
    case REQUEST_LAST_MATCH_BY_TAG:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_LAST_MATCH_BY_TAG:
      const { data: matchData } = action.payload.match;
      return {
        ...state,
        isFetching: false,
        match: action.payload.match,
        inProgress: matchData !== null && !matchData.winCondition
      };
    default:
      return state;
  }
}

export default {
  matches,
  match,
  currentMatch,
  currentMatchByTag,
  lastMatchByTag
};
