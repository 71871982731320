import { combineReducers } from "redux";

import sidebarReducer from "./sidebar";
import matchReducer from "./matches";

export default combineReducers({
  sidebarStatus: sidebarReducer.status,
  matches: matchReducer.matches,
  selectedMatch: matchReducer.match,
  currentMatch: matchReducer.currentMatch,
  currentMatchByTag: matchReducer.currentMatchByTag,
  lastMatchByTag: matchReducer.lastMatchByTag
});
