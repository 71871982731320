import React from "react";
import { render } from "react-dom";

import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import kqStatsApp from "./reducers";
//TODO: For some reason trailing / is required here. It doesn't default to the directory
import rootSaga from "./sagas/";

import "./style/reset.css";
import "./style/index.css";
import "./style/stream.scss";

import Root from "./components/Root";

import * as serviceWorker from "./serviceWorker";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(kqStatsApp, applyMiddleware(sagaMiddleware, logger));

sagaMiddleware.run(rootSaga);

render(<Root store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
