import React, { Component } from "react";
import _ from "underscore";

import { playerIcon } from "../helpers/playerImage";
import messageHelper from "../helpers/messageHelper";

export default class MatchMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasHeight: 0
    };
  }
  componentDidMount() {
    const { socket, stream } = this.props;
    if (stream && socket) {
      const {
        requestLastMatchByTag,
        match: {
          params: { tag }
        }
      } = this.props;
      socket.onmessage = event => {
        if (messageHelper.parseMessage(event.data).type === "matchSaved") {
          requestLastMatchByTag(tag);
        }
      };
    }
    window.onload = () => {
      this.updateCanvas();
    }
    this.setState({ canvasHeight: this.refs.canvas.clientHeight });
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  updateCanvas() {
    const { map, events, goldOnLeft } = this.props;

    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");
    const img = this.refs.image;

    const cover = this.refs.cover.getContext("2d");

    cover.clearRect(0, 0, canvas.width, canvas.height);

    if (
      map === "map_bonus_military" ||
      map === "map_bonus_snail" ||
      map === "map_josh" ||
      map === "map_nik"
    ) {
      ctx.fillStyle = "#5c5c5c";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.textAlign = "center";
      ctx.font = "30px Arial";
      ctx.fillStyle = "#000000";
      ctx.fillText(
        "No image for this map",
        canvas.width / 2,
        canvas.height / 2
      );
    }

    img.onload = () => {
      const width = 1920;
      const height = 1080;

      const scaleH = !goldOnLeft ? -1 : 1;
      const posX = !goldOnLeft ? width * -1 : 0;

      ctx.save();
      ctx.scale(scaleH, 1);
      ctx.drawImage(img, posX, 0, width, height);
      ctx.restore();
    };

    const kills = _.filter(events, { type: "playerKill" });
    const blueBotKills = _.filter(kills, event => {
      return event.victor.team === "blue" && event.victim.type === "bot";
    });
    const blueDroneKills = _.filter(kills, event => {
      return event.victor.team === "blue" && event.victim.type === "drone";
    });
    const blueSpeedDroneKills = _.filter(kills, event => {
      return (
        event.victor.team === "blue" &&
        event.victim.type === "drone" &&
        event.victim.hasSpeed
      );
    });
    const blueWarriorKills = _.filter(kills, event => {
      return event.victor.team === "blue" && event.victim.type === "warrior";
    });
    const blueSpeedWarriorKills = _.filter(kills, event => {
      return (
        event.victor.team === "blue" &&
        event.victim.type === "warrior" &&
        event.victim.hasSpeed
      );
    });
    const blueQueenKills = _.filter(kills, event => {
      return event.victor.team === "blue" && event.victim.type === "queen";
    });
    const goldBotKills = _.filter(kills, event => {
      return event.victor.team === "gold" && event.victim.type === "bot";
    });
    const goldDroneKills = _.filter(kills, event => {
      return event.victor.team === "gold" && event.victim.type === "drone";
    });
    const goldSpeedDroneKills = _.filter(kills, event => {
      return (
        event.victor.team === "gold" &&
        event.victim.type === "drone" &&
        event.victim.hasSpeed
      );
    });
    const goldWarriorKills = _.filter(kills, event => {
      return event.victor.team === "gold" && event.victim.type === "warrior";
    });
    const goldSpeedWarriorKills = _.filter(kills, event => {
      return (
        event.victor.team === "gold" &&
        event.victim.type === "warrior" &&
        event.victim.hasSpeed
      );
    });
    const goldQueenKills = _.filter(kills, event => {
      return event.victor.team === "gold" && event.victim.type === "queen";
    });

    const berryCarrierKills = _.filter(kills, event => {
      return event.victim.hasBerry;
    });
    const snailRiderKills = _.filter(kills, event => {
      return event.victim.isRidingSnail;
    });
    const snailEatKills = _.filter(kills, event => {
      return event.victim.isBeingEaten;
    });
    const snailEaterKills = _.filter(kills, event => {
      return event.victim.isEating;
    });
    // const berries = _.filter(events, { type: "berryDeposit" });

    this.drawOnCanvas(cover, canvas, blueBotKills, {
      fill: "gold",
      stroke: "gray",
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, blueDroneKills, {
      fill: "gold",
      stroke: "white",
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, blueSpeedDroneKills, {
      fill: "gold",
      stroke: "yellow",
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, blueWarriorKills, {
      fill: "gold",
      stroke: "white",
      shape: "triangle"
    });
    this.drawOnCanvas(cover, canvas, blueSpeedWarriorKills, {
      fill: "gold",
      stroke: "yellow",
      shape: "triangle"
    });
    this.drawOnCanvas(cover, canvas, blueQueenKills, {
      fill: "gold",
      stroke: "white",
      shape: "rectangle"
    });
    this.drawOnCanvas(cover, canvas, goldBotKills, {
      fill: "blue",
      stroke: "gray",
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, goldDroneKills, {
      fill: "blue",
      stroke: "white",
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, goldSpeedDroneKills, {
      fill: "blue",
      stroke: "yellow",
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, goldWarriorKills, {
      fill: "blue",
      stroke: "white",
      shape: "triangle"
    });
    this.drawOnCanvas(cover, canvas, goldSpeedWarriorKills, {
      fill: "blue",
      stroke: "yellow",
      shape: "triangle"
    });
    this.drawOnCanvas(cover, canvas, goldQueenKills, {
      fill: "blue",
      stroke: "white",
      shape: "rectangle"
    });

    this.drawOnCanvas(cover, canvas, berryCarrierKills, {
      stroke: "#aa3dff",
      // size: 15,
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, snailRiderKills, {
      stroke: "#a30022",
      // size: 15,
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, snailEatKills, {
      stroke: "#fe41f6",
      // size: 15,
      shape: "circle"
    });
    this.drawOnCanvas(cover, canvas, snailEaterKills, {
      stroke: "#0ad53c",
      // size: 15,
      shape: "circle"
    });

    // this.drawOnCanvas(cover, canvas, berries, {
    //   fill: "purple",
    //   shape: "circle"
    // });
  }

  // Base Colors - Gray: Bot, White: Drone, Red: Warrior
  // Modifiers (Dashed) - Purple: Berry, Yellow: Speed
  setStrokeColor(ctx, player) {
    switch (player.type) {
      case "bot":
        ctx.strokeStyle = "gray";
        break;
      case "drone":
        if (player.hasSpeed) {
          ctx.strokeStyle = "yellow";
        } else {
          ctx.strokeStyle = "white";
        }
        break;
      case "warrior":
        if (player.hasSpeed) {
          ctx.strokeStyle = "red";
        } else {
          ctx.strokeStyle = "black";
        }
        break;
      case "queen":
        ctx.strokeStyle = "white";
        break;
      default:
        ctx.strokeStyle = "white";
        break;
    }
  }

  drawOnCanvas(ctx, canvas, events, options) {
    const { fill = null, stroke, strokeWidth = 5, shape, size = 30 } = options;
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = stroke;
    ctx.fillStyle = fill;

    _.map(events, event => {
      let { x, y, victor, victim, player } = event;

      const image1 = this.refs[`player-icon-${victor.id}`];
      const image2 = this.refs[`player-icon-${victim.id}`];

      if (true) {
        ctx.setLineDash([0]);

        this.setStrokeColor(ctx, victor);

        ctx.strokeRect(
          x - size / 2,
          canvas.height - y - size / 2,
          size / 2,
          size
        );

        this.setStrokeColor(ctx, victim);
        ctx.strokeRect(x + 2, canvas.height - y - size / 2, size / 2 - 2, size);
        if (victim.hasBerry) {
          ctx.strokeStyle = "purple";
          ctx.setLineDash([1]);
          ctx.strokeRect(
            x + 2,
            canvas.height - y - size / 2,
            size / 2 - 2,
            size
          );
        }

        ctx.drawImage(
          image1,
          0,
          0,
          37,
          75,
          x - size / 2,
          canvas.height - y - size / 2,
          size / 2,
          size
        );
        ctx.drawImage(
          image2,
          37,
          0,
          37,
          75,
          x,
          canvas.height - y - size / 2,
          size / 2,
          size
        );
      } else {
        switch (shape) {
          case "rectangle":
            ctx.fillRect(
              x - size / 2,
              canvas.height - y - size / 2,
              size,
              size
            );
            ctx.strokeRect(
              x - size / 2,
              canvas.height - y - size / 2,
              size,
              size
            );
            break;
          case "triangle":
            ctx.beginPath();
            ctx.moveTo(x, canvas.height - y - size / 2);
            ctx.lineTo(x + size / 2, canvas.height - y + size / 2);
            ctx.lineTo(x - size / 2, canvas.height - y + size / 2);
            ctx.closePath();
            ctx.stroke();
            break;
          case "circle":
            ctx.beginPath();
            ctx.arc(x, canvas.height - y, size / 2, 0, 2 * Math.PI);
            if (fill) ctx.fill();
            ctx.stroke();
            break;
          default:
            break;
        }
      }

      // Debugging base x/y
      // ctx.strokeStyle = "red";
      // ctx.beginPath();
      // ctx.arc(x, canvas.height - y, 1, 0, 2 * Math.PI);
      // ctx.stroke();
    });
  }

  preloadPlayerImages(players) {
    return _.map(players, player => {
      return (
        <img
          ref={`player-icon-${player.id}`}
          style={{ display: "none" }}
          src={playerIcon(player)}
        />
      );
    });
  }

  render() {
    const { map, players } = this.props;

    return (
      <div className="map">
        <div
          ref="container"
          className="map-container"
          style={{ height: this.state.canvasHeight }}
        >
          <canvas
            className="map-image"
            ref="canvas"
            width={1920}
            height={1080}
          />
          <canvas ref="cover" width={1920} height={1080} />
        </div>
        {false && (
          <div className="map-description">
            <div>
              This is a map of deaths by team color. Shapes and border colors
              represent the following
            </div>
            <ul>
              <li>Circles = Bots/Drones</li>
              <li>Triangles = Warriors</li>
              <li>Squares = Queens</li>
              <li>Gray Border = Bot</li>
              <li>White Border = Normal</li>
              <li>Yellow Border = Speed</li>
            </ul>
            <ul>
              <li>Purple Inner = Had berry</li>
              <li>Red Inner = Riding snail</li>
              <li>Pink Inner = Eaten by snail</li>
              <li>Green Inner = Was eating with snail</li>
            </ul>
          </div>
        )}
        <img
          ref="image"
          alt={{ map }}
          style={{ display: "none" }}
          src={`/maps/${map}.png`}
        />
        {this.preloadPlayerImages(players)}
      </div>
    );
  }
}
