const playerImage = player => {
  const { team, type, slot } = player;
  return `/players/${team}-${type}${
    type === "bot" || type === "queen" ? "-" : ""
  }${slot}.png`;
};

const playerIcon = player => {
  const { team, type, slot } = player;
  return `/players/${team}-icon-${slot}.png`;
};

const playerIconKD = player => {
  const { team, type, slot } = player;
  return `/players/${team}-icon-${slot}-kd.png`;
};

module.exports = {
  playerImage,
  playerIcon,
  playerIconKD
};
