import React, { Component } from "react";
import messageHelper from "../../helpers/messageHelper";

class Tournament extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentInfo: null
    };

    this.getCurrentTournamentInfo = this.getCurrentTournamentInfo.bind(this);
  }

  componentDidMount() {
    const { socket } = this.props;

    if (socket) {
      socket.onmessage = event => {
        const messageType = messageHelper.parseMessage(event.data).type;
        if (["gamestart", "gameend", "victory"].includes(messageType)) {
          this.getCurrentTournamentInfo();
        }
      };
    }

    this.interval = setInterval(() => {
      this.getCurrentTournamentInfo();
    }, 5000);

    this.getCurrentTournamentInfo();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getCurrentTournamentInfo() {
    fetch("https://baltimorekillerqueen.com/api/now")
      .then(res => {
        return res.json();
      })
      .then(res => {
        this.setState({
          tournamentInfo: res
        });
      });

    // Test data in case endpoint doesn't work
    // this.setState({tournamentInfo: {"current_match": {"blue_team": "Team 6", "blue_score": 1, "gold_team": "Team 8", "gold_score": 0, "cabinet_name": "BMORE"}}})
    // this.setState({
    //   tournamentInfo: {
    //     active_matches: [
    //       {
    //         blue_team: "Team 6",
    //         blue_score: 1,
    //         gold_team: "Team 8",
    //         gold_score: 0,
    //         cabinet_name: "BMORE"
    //       },
    //       {
    //         blue_team: "Team 1",
    //         blue_score: 3,
    //         gold_team: "Team 2",
    //         gold_score: 2,
    //         cabinet_name: "TEST1"
    //       }
    //     ]
    //   }
    // });
  }

  renderTournamentInfo(tournamentInfo, tag, team, value) {
    const key = `${team}_${value}`;

    if (tournamentInfo && tournamentInfo["current_match"]) {
      return tournamentInfo["current_match"][key];
    } else if (tournamentInfo && tournamentInfo["active_matches"]) {
      let currentMatch = tournamentInfo["active_matches"].filter(
        match => match.cabinet_name.toLowerCase() === tag
      )[0];
      return currentMatch[key];
    } else {
      switch (value) {
        case "team":
          return `${team.charAt(0).toUpperCase() + team.slice(1)} Team`;
        case "score":
          return 0;
        default:
          return;
      }
    }
  }

  render() {
    const {
      match: {
        params: { tag, team, value }
      }
    } = this.props;
    const { tournamentInfo } = this.state;

    return (
      <div className="stream-tournament-info">
        {this.renderTournamentInfo(tournamentInfo, tag, team, value)}
      </div>
    );
  }
}

export default Tournament;
