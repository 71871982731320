import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import _ from "underscore";

const BLUE_COLOR = "#504de2";
const BLUE_COLOR_MID_OPACITY = "rgba(80, 77, 226, 0.5)";
const GOLD_COLOR = "#d2a608";
const GOLD_COLOR_MID_OPACITY = "rgba(210, 166, 8, 0.5)";

export default class MatchGraphs extends Component {
  constructor(props) {
    super(props);

    this.calculateBerryData = this.calculateBerryData.bind(this);
    this.calculateWarriorData = this.calculateWarriorData.bind(this);
    this.calculateSnailData = this.calculateSnailData.bind(this);
    this.calculateKillData = this.calculateKillData.bind(this);
  }

  calculateBerryData() {
    const { match } = this.props;
    const { data } = match;
    const { events } = data;

    // TODO: Refactor undefined bug here
    const startEvent = _.find(events, { type: "gamestart" });
    const endEvent =
      _.find(events, { type: "gameend" }) ||
      _.find(events, { type: "victory" });
    const berryEvents = _.filter(events, { type: "berryDeposit" });

    const blueBerries = this.calculateBerriesForTeam(
      [...berryEvents, endEvent],
      startEvent.time,
      "blue"
    );

    const goldBerries = this.calculateBerriesForTeam(
      [...berryEvents, endEvent],
      startEvent.time,
      "gold"
    );

    return {
      datasets: [
        {
          label: "# of Blue Berries",
          data: blueBerries,
          borderColor: BLUE_COLOR,
          lineTension: 0,
          fill: false
        },
        {
          label: "# of Gold Berries",
          data: goldBerries,
          borderColor: GOLD_COLOR,
          lineTension: 0,
          fill: false
        }
      ]
    };
  }

  calculateBerriesForTeam(events, startTime, berryTeam) {
    let count = 0;
    let berryData = _.map(events, event => {
      if (!event) return null;

      const { type } = event;
      if (type === "gameend" || type === "victory") {
        return { x: event.time - startTime, y: count };
      }

      let {
        time,
        player: { team }
      } = event;

      if (team === berryTeam) {
        count = count + 1;
        return { x: time - startTime, y: count };
      } else {
        return null;
      }
    });

    berryData = _.compact(berryData);

    return [{ x: 0, y: 0 }, ...berryData];
  }

  calculateWarriorData() {
    const { match } = this.props;
    const { data } = match;
    const { events } = data;

    // TODO: Refactor undefined bug here
    const startEvent = _.find(events, { type: "gamestart" });
    const endEvent =
      _.find(events, { type: "gameend" }) ||
      _.find(events, { type: "victory" });
    const warriorEvents = _.filter(events, event => {
      const { type } = event;
      return ["playerKill", "useMaiden"].includes(type);
    });

    const blueWarriors = this.calculateWarriorsForTeam(
      [...warriorEvents, endEvent],
      startEvent.time,
      "blue"
    );

    const goldWarriors = this.calculateWarriorsForTeam(
      [...warriorEvents, endEvent],
      startEvent.time,
      "gold"
    );

    return {
      datasets: [
        {
          label: "Blue Warriors",
          data: blueWarriors.all,
          borderColor: BLUE_COLOR,
          lineTension: 0,
          fill: false,
          step: true
        },
        {
          label: "Blue Speed Warriors",
          data: blueWarriors.speed,
          borderColor: BLUE_COLOR,
          borderDash: [1],
          lineTension: 0,
          fill: false,
          step: true
        },
        {
          label: "Gold Warriors",
          data: goldWarriors.all,
          borderColor: GOLD_COLOR,
          lineTension: 0,
          fill: false
        },
        {
          label: "Gold Speed Warriors",
          data: goldWarriors.speed,
          borderColor: GOLD_COLOR,
          borderDash: [1],
          lineTension: 0,
          fill: false
        }
      ]
    };
  }

  calculateWarriorsForTeam(events, startTime, warriorTeam) {
    let warriorCount = 0;
    let speedWarriorCount = 0;

    let warriorData = { all: [], speed: [] };

    _.each(events, event => {
      if (!event) return null;

      const { time, type } = event;

      if (type === "gameend" || type === "victory") {
        warriorData = {
          all: [
            ...warriorData.all,
            { x: event.time - startTime, y: warriorCount }
          ],
          speed: [
            ...warriorData.speed,
            { x: event.time - startTime, y: speedWarriorCount }
          ]
        };
        return;
      }

      const { player, victim, maiden } = event;
      const team = player ? player.team : victim.team;

      // TODO: Refactor this mess of conditions into something simpler
      if (team === warriorTeam) {
        switch (type) {
          case "playerKill":
            if (victim.type === "warrior") {
              warriorCount -= 1;

              warriorData = {
                ...warriorData,
                all: [
                  ...warriorData.all,
                  { x: time - startTime, y: warriorCount }
                ]
              };

              if (victim.hasSpeed) {
                speedWarriorCount -= 1;

                warriorData = {
                  ...warriorData,
                  speed: [
                    ...warriorData.speed,
                    { x: time - startTime, y: speedWarriorCount }
                  ]
                };
              }
            }
            break;
          case "useMaiden":
            // REFACTOR Fix same maiden bug here
            if (maiden) {
              if (maiden.type === "warrior") {
                warriorCount += 1;

                warriorData = {
                  ...warriorData,
                  all: [
                    ...warriorData.all,
                    { x: time - startTime, y: warriorCount }
                  ]
                };

                if (player.hasSpeed) {
                  speedWarriorCount += 1;

                  warriorData = {
                    ...warriorData,
                    speed: [
                      ...warriorData.speed,
                      { x: time - startTime, y: speedWarriorCount }
                    ]
                  };
                }
              }
            }
            break;
          default:
            break;
        }
      }
    });

    return {
      all: [{ x: 0, y: 0 }, ...warriorData.all],
      speed: [{ x: 0, y: 0 }, ...warriorData.speed]
    };
  }

  calculateSnailData() {
    const { match, goldOnLeft } = this.props;
    const { data } = match;
    const { events } = data;

    let lastSnailPosition = 960;

    // TODO: Refactor undefined bug here
    const startEvent = _.find(events, { type: "gamestart" });
    const endEvent = _.find(events, { type: "victory" });
    let snailEvents = _.filter(events, event => {
      const { type } = event;
      return ["getOnSnail", "getOffSnail", "snailEat"].includes(type);
    });

    snailEvents = [...snailEvents, endEvent];

    const startTime = startEvent.time;

    let snailPositionData = _.map(snailEvents, event => {
      if (!event) return null;

      const { time, type, x } = event;

      if (type === "gameend" || type === "victory") {
        if (event.winCondition === "snail") {
          if (event.team === "gold") {
            lastSnailPosition = goldOnLeft ? 0 : 1920;
          } else {
            lastSnailPosition = goldOnLeft ? 1920 : 0;
          }
        }
        return { x: time - startTime, y: lastSnailPosition };
      } else {
        lastSnailPosition = x;
        return { x: time - startTime, y: x };
      }
    });

    snailPositionData = [{ x: 0, y: 960 }, ...snailPositionData];

    return {
      datasets: [
        {
          label: "Snail Position",
          data: snailPositionData,
          borderColor: "#d265ff",
          lineTension: 0,
          fill: false
        }
      ]
    };
  }

  calculateKillData() {
    const { match } = this.props;
    const { data } = match;
    const { events } = data;

    const startEvent = _.find(events, { type: "gamestart" });
    const endEvent = _.find(events, { type: "victory" });
    const killEvents = _.filter(events, { type: "playerKill" });

    const startTime = startEvent.time;

    let blueKills = 0;
    let goldKills = 0;

    let killData = { blue: [{ x: 0, y: 0 }], gold: [{ x: 0, y: 0 }] };

    _.map(killEvents, event => {
      const {
        time,
        victor: { team }
      } = event;

      switch (team) {
        case "blue":
          blueKills += 1;
          killData.blue.push({ x: time - startTime, y: blueKills });
          break;
        case "gold":
          goldKills += 1;
          killData.gold.push({ x: time - startTime, y: goldKills });
          break;
        default:
          break;
      }
    });

    if (endEvent) {
      const { time } = endEvent;
      killData.blue.push({ x: time - startTime, y: blueKills });
      killData.gold.push({ x: time - startTime, y: goldKills });
    }

    return {
      datasets: [
        {
          label: "Blue Kills",
          data: killData.blue,
          borderColor: BLUE_COLOR,
          lineTension: 0,
          fill: false
        },
        {
          label: "Gold Kills",
          data: killData.gold,
          borderColor: GOLD_COLOR,
          lineTension: 0,
          fill: false
        }
      ]
    };
  }

  createGraphOptions(options) {
    const { goldOnLeft } = this.props;
    const { title, min, max, stepSize, teamBGColors } = options;

    let config = {
      title: {
        text: title,
        display: true
      },
      legend: {
        position: "bottom"
      },
      animation: false,
      scales: {
        yAxes: [
          {
            ticks: {
              min: min,
              max: max,
              stepSize: stepSize
            }
          }
        ],
        xAxes: [
          {
            type: "time",
            time: {
              displayFormats: {
                second: "m:ss"
              },
              unit: "second"
            }
          }
        ]
      }
    };

    if (teamBGColors) {
      const bounds = (() => {
        if (goldOnLeft) {
          return {
            blue: { yMax: max, yMin: max / 2 },
            gold: { yMax: max / 2, yMin: 0 }
          };
        } else {
          return {
            gold: { yMax: max, yMin: max / 2 },
            blue: { yMax: max / 2, yMin: 0 }
          };
        }
      })();

      config = {
        ...config,
        annotation: {
          drawTime: "beforeDatasetsDraw",
          annotations: [
            {
              type: "box",
              backgroundColor: GOLD_COLOR_MID_OPACITY,
              yScaleID: "y-axis-0",
              ...bounds.gold
            },
            {
              type: "box",
              backgroundColor: BLUE_COLOR_MID_OPACITY,
              yScaleID: "y-axis-0",
              ...bounds.blue
            }
          ]
        }
      };
    }

    return config;
  }

  render() {
    const berryGraphOptions = this.createGraphOptions({
      title: "Berries (No berry kick in data)",
      min: 0,
      max: 12,
      stepSize: 2
    });
    const snailGraphOptions = this.createGraphOptions({
      title: "Snail (Last Known Position)",
      min: 0,
      max: 1920,
      stepSize: 480,
      teamBGColors: true
    });
    const warriorGraphOptions = this.createGraphOptions({
      title: "Warriors",
      min: 0,
      max: 4,
      stepSize: 1
    });
    const killGraphOptions = this.createGraphOptions({
      title: "Kills",
      min: 0,
      stepSize: 5
    });

    return (
      <div className="graphs">
        <Line data={this.calculateBerryData} options={berryGraphOptions} />
        <Line data={this.calculateSnailData} options={snailGraphOptions} />
        <Line data={this.calculateWarriorData} options={warriorGraphOptions} />
        <Line data={this.calculateKillData} options={killGraphOptions} />
      </div>
    );
  }
}
