import { call, put, takeEvery } from "redux-saga/effects";
import { matchActions } from "../actions";

function* fetchMatches(action) {
  try {
    const tag = action.payload.tag || "bmore"
    const response = yield call(
      fetch,
      `/api/${tag}/matches/page/${action.payload.page}`
    );
    const responseBody = yield response.json();
    yield put(matchActions.receiveMatches(responseBody));
  } catch (e) {
    console.log("Error", e);
    // yield put(fetchFailed(e));
    return;
  }
}

function* fetchMatchInfo(action) {
  try {
    const response = yield call(
      fetch,
      `/api/matches/info/${action.payload.matchId}`
    );
    const responseBody = yield response.json();
    yield put(matchActions.receiveMatchInfo(responseBody));
  } catch (e) {
    console.log("Error", e);
    // yield put(fetchFailed(e));
    return;
  }
}

function* fetchMatch(action) {
  try {
    const response = yield call(
      fetch,
      `/api/matches/${action.payload.matchId}`
    );
    const responseBody = yield response.json();
    yield put(matchActions.receiveMatch(responseBody));
  } catch (e) {
    console.log("Error", e);
    // yield put(fetchFailed(e));
    return;
  }
}

function* fetchCurrentMatch(action) {
  try {
    const tag = action.payload.tag || "bmore"
    const response = yield call(fetch, `/api/${tag}/current_match`);
    const responseBody = yield response.json();
    yield put(matchActions.receiveCurrentMatch(responseBody));
  } catch (e) {
    console.log("Error", e);
    // yield put(fetchFailed(e));
    return;
  }
}

function* fetchCurrentMatchByTag(action) {
  try {
    const response = yield call(
      fetch,
      `/api/${action.payload.tag}/current_match`
    );
    const responseBody = yield response.json();
    yield put(matchActions.receiveCurrentMatchByTag(responseBody));
  } catch (e) {
    console.log("Error", e);
    // yield put(fetchFailed(e));
    return;
  }
}
function* fetchLastMatchByTag(action) {
  try {
    const response = yield call(fetch, `/api/${action.payload.tag}/last_match`);
    const responseBody = yield response.json();
    yield put(matchActions.receiveLastMatchByTag(responseBody));
  } catch (e) {
    console.log("Error", e);
    // yield put(fetchFailed(e));
    return;
  }
}

function* watchRequestMatches() {
  yield takeEvery(matchActions.REQUEST_MATCHES, fetchMatches);
}

function* watchRequestMatchInfo() {
  yield takeEvery(matchActions.REQUEST_MATCH_INFO, fetchMatchInfo);
}

function* watchRequestMatch() {
  yield takeEvery(matchActions.REQUEST_MATCH, fetchMatch);
}

function* watchRequestCurrentMatch() {
  yield takeEvery(matchActions.REQUEST_CURRENT_MATCH, fetchCurrentMatch);
}

function* watchRequestCurrentMatchByTag() {
  yield takeEvery(
    matchActions.REQUEST_CURRENT_MATCH_BY_TAG,
    fetchCurrentMatchByTag
  );
}

function* watchRequestLastMatchByTag() {
  yield takeEvery(matchActions.REQUEST_LAST_MATCH_BY_TAG, fetchLastMatchByTag);
}

export default {
  watchRequestMatches,
  watchRequestMatchInfo,
  watchRequestMatch,
  watchRequestCurrentMatch,
  watchRequestCurrentMatchByTag,
  watchRequestLastMatchByTag
};
