import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { matchActions } from "../actions";

import App from "../components/App";

const mapStateToProps = state => {
  return {
    viewingCurrentMatch: state.currentMatch.viewing,
    matchInProgress: state.currentMatch.inProgress
  };
};
const mapDispatchToProps = dispatch => ({
  fetchCurrentMatch: tag => dispatch(matchActions.requestCurrentMatch(tag)),
  fetchMatchInfo: matchId => dispatch(matchActions.requestMatchInfo(matchId))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
