import React, { Component } from "react";
import { Link } from "react-router-dom";

class Directory extends Component {
  render() {
    return (
      <div className="directory">
        <div className="title">Welcome to BmoreKQ.com</div>
        <div className="links">
          <Link to="/matches">
            <div className="btn btn-primary">Matches</div>
          </Link>
          <Link to="/stats">
            <div className="btn btn-primary">Overall Stats (In progress)</div>
          </Link>
          <Link to="/players">
            <div className="btn btn-primary">Players (Coming soon)</div>
          </Link>
          <Link to="/players">
            <div className="btn btn-primary">How to Play (Coming soon)</div>
          </Link>
          <Link to="/hardware">
            <div className="btn btn-primary">Hardware Status</div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Directory;
