import { all } from "redux-saga/effects";
import matchSagas from "./matches";

export default function* rootSaga() {
  yield all([
    matchSagas.watchRequestMatches(),
    matchSagas.watchRequestMatchInfo(),
    matchSagas.watchRequestMatch(),
    matchSagas.watchRequestCurrentMatch(),
    matchSagas.watchRequestCurrentMatchByTag(),
    matchSagas.watchRequestLastMatchByTag()
  ]);
}
